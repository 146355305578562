<template>
  <div class="mx-auto w-80 sm:w-128">
    <p class="w-full my-2 text-center text-lg font-semibold text-gray-900">Wähle eine Playlist aus, um den Song hinzuzufügen</p>
    <div class="flex justify-center my-2">
      <track-card
        v-if="track"
        :track="track"
        size="sm"
      />
      <loader
        v-else-if="loadingTrack"
        loadingText="Lade Song"
      />
      <p v-else>Song Details konnten nicht geladen werden</p>
    </div>
    <playlist-selector
      @select="addTrackToPlaylist"
    />
  </div>
</template>

<script>
import TrackCard from '../components/TrackCard.vue'
import PlaylistSelector from '../components/PlaylistSelector.vue'
import { mapState } from 'vuex'

export default {
  components: { TrackCard, PlaylistSelector },
  computed: {
    ...mapState({
      track: state => state.playlist.trackToAdd
    })
  },
  data () {
    return {
      loadingTrack: false
    }
  },
  async beforeMount () {
    if (!this.track) {
      this.loadingTrack = true
      const { data } = await this.axios.get(`track/${this.$route.params.trackId}`)

      if (!data.reasons) {
        this.$store.commit('playlist/setTrackToAdd', data)
      } else {
        console.log('da ging was schief: ', data.reasons)
      }

      this.loadingTrack = false
    }
  },
  destroyed () {
    this.$store.commit('playlist/resetTrackToAdd')
  },
  methods: {
    addTrackToPlaylist (playlistId) {
      this.$store.dispatch('playlist/addTrack',
        { playlistId, trackId: this.track.id })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
