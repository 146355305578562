<template>
    <div class="track" :class="size + [shrink ? ' shrink' : ' no-shrink']">
        <div class="cover" :class="coverSize">
            <img v-if="track.image" class="w-full h-full" :src="track.image" />
            <fa-icon v-else icon="image" :size="coverSize === 'md' ? '3x' : '2x'" />
        </div>
        <div
            :id="`marquee-container-${track.id}`"
            class="description"
            :class="descriptionStyle"
        >
            <div
                :id="`marquee-title-${track.id}`"
                class="marquee title"
                :class="autoplayClass"
                :style="cssVars"
            >
                <div class="marquee-inner title" aria-hidden="true">
                    <span
                        :id="`marquee-span-title-${track.id}`"
                        :class="titleStyle"
                    >
                        {{ track.name }}
                    </span>
                </div>
            </div>
            <div
                :id="`marquee-artist-${track.id}`"
                class="marquee artist"
                :class="autoplayClass"
                :style="cssVars"
            >
                <div class="marquee-inner artist" aria-hidden="true">
                    <span
                        :id="`marquee-span-artist-${track.id}`"
                        :class="artistStyle"
                    >
                        {{ track.artists.join(', ') }}
                    </span>
                </div>
            </div>
            <div
                v-if="showAlbum"
                :id="`marquee-album-${track.id}`"
                class="marquee album"
                :class="autoplayClass"
                :style="cssVars"
            >
                <div class="marquee-inner album" aria-hidden="true">
                    <span
                        :id="`marquee-span-album-${track.id}`"
                        :class="albumStyle"
                    >
                        {{ track.album }}
                    </span>
                </div>
            </div>
            <div v-else
                class="marquee text-sm flex content-center"
                :style="{height: spanHeightArtist}"
            >
                <slot></slot>
            </div>
            <div v-if="durationPos === 'bottom'" class="length">
                <p v-if="track.length" class="text-base">{{ toDuration(track.length) }}</p>
            </div>
        </div>
        <div v-if="durationPos === 'right'" class="length">
            <p v-if="track.length" class="ml-2">{{ toDuration(track.length) }}</p>
        </div>
    </div>
</template>

<script>
import timeFormatter from '../mixins/timeFormatter'

export default {
    mixins: [ timeFormatter ],
    props: {
        track: {
            type: Object
        },
        coverSize: {
            type: String,
            default: () => ('sm'),
            validator: (value) => {
                return ['sm', 'md'].includes(value)
            }
        },
        autoMarquee: {
            type: Boolean,
            default: () => false
        },
        descriptionStyle: {
            type: Array,
            default: () => ([])
        },
        titleStyle: {
            type: Array,
            default: () => (['text-white', 'tracking-wide', 'font-semibold'])
        },
        artistStyle: {
            type: Array,
            default: () => (['text-secondary'])
        },
        albumStyle: {
            type: Array,
            default: () => (['text-secondary'])
        },
        showAlbum: {
            type: Boolean,
            default: () => true
        },
        durationPos: {
            type: String,
            default: () => 'right',
            validator: (value) => {
                return ['right', 'bottom'].includes(value)
            }
        },
        size: {
            type: String,
            required: true,
            validator: (value) => {
                return ['sm', 'md'].includes(value)
            }
        },
        shrink: {
            type: Boolean,
            default: () => true
        }
    },
    computed: {
        cssVars () {
            const diffTitle = this.marqueeContainerWidth - this.spanWidthTitle
            const diffArtist = this.marqueeContainerWidth - this.spanWidthArtist
            const diffAlbum = this.marqueeContainerWidth - this.spanWidthAlbum
            return {
                '--move-final-title': diffTitle - 30 + 'px',
                '--move-final-artist': diffArtist - 30 + 'px',
                '--move-final-album': diffAlbum - 30 + 'px',
                '--animation-duration-title': (diffTitle < 0 ? -0.025 * diffTitle + 2 : 0) + 's',
                '--animation-duration-artist': (diffArtist < 0 ? -0.025 * diffArtist + 2 : 0) + 's',
                '--animation-duration-album': (diffAlbum < 0 ? -0.025 * diffAlbum + 2 : 0) + 's'
            }
        },
        autoplayClass () {
            return this.autoMarquee === true ? 'on-auto' : 'on-hover'
        }
    },
    mounted () {
        this.sizeMarquee()

        this.resizeListener = window.matchMedia('(min-width: 640px)')
        this.resizeListener.addListener(this.handleMedia)
    },
    beforeDestroy () {
        this.resizeListener.removeListener(this.handleMedia)
    },
    data () {
        return {
            marqueeContainerWidth: '0px',
            spanWidthTitle: '0px',
            spanWidthArtist: '0px',
            spanWidthAlbum: '0px',
            spanHeightArtist: '0px',
            resizeListener: null
        }
    },
    methods: {
        handleMedia () {
            this.sizeMarquee()
        },
        sizeMarquee () {
            this.marqueeContainerWidth = document.getElementById(`marquee-container-${this.track.id}`).getBoundingClientRect().width
            this.spanWidthTitle = document.getElementById(`marquee-span-title-${this.track.id}`).getBoundingClientRect().width
            const artistRect = document.getElementById(`marquee-span-artist-${this.track.id}`).getBoundingClientRect()
            this.spanWidthArtist = artistRect.width
            if (this.showAlbum) this.spanWidthAlbum = document.getElementById(`marquee-span-album-${this.track.id}`).getBoundingClientRect().width
            this.spanHeightArtist = `${artistRect.height}px`
        }
    }
}
</script>

<style lang="sass" scoped>
@import ../assets/styles/typography.sass

$md-width-small: 325px
//$md-width-medium: 600px
$md-width-medium: 565px
$sm-width-small: 300px
$sm-width-medium: 400px

.track
    @apply flex flex-no-wrap items-center justify-between

    &.sm:not(.no-shrink)
        @media screen and (min-width: 640px)
            max-width: $sm-width-medium
            min-width: $sm-width-medium

        @media screen and (max-width: 639px)
            max-width: $sm-width-small
            min-width: $sm-width-small
    
    &.sm
        &.no-shrink
            max-width: $sm-width-medium
            min-width: $sm-width-medium

    &.md
        @media screen and (min-width: 640px)
            max-width: $md-width-medium
            min-width: $md-width-medium

        @media screen and (max-width: 639px)
            max-width: $md-width-small
            min-width: $md-width-small

    .cover
        @apply rounded-full overflow-hidden flex-shrink-0 border-2 border-gray-200 flex items-center justify-center

        &.sm
            @media screen and (min-width: 640px)
                @apply w-16 h-16

            @media screen and (max-width: 639px)
                @apply w-12 h-12

        &.md
            @media screen and (min-width: 640px)
                @apply w-24 h-24

            @media screen and (max-width: 639px)
                @apply w-16 h-16

    .description
        @apply w-full overflow-hidden flex flex-col mx-2

    .length
        @apply flex-none

.marquee
    position: relative
    overflow: hidden

.marquee-inner
    width: fit-content
    display: flex
    position: relative

    &.title
        animation: marquee-title var(--animation-duration-title) linear 0s infinite normal none paused

    &.artist
        animation: marquee-artist var(--animation-duration-artist) linear 0s infinite normal none paused

    &.album
        animation: marquee-album var(--animation-duration-album) linear 0s infinite normal none paused
    // animation-play-state: paused

.marquee span
    white-space: nowrap

.marquee

    &.on-hover:hover .marquee-inner
        animation-play-state: running

    &.on-auto .marquee-inner
        animation-play-state: running

@keyframes marquee-title
    0%
        transform: translate3d(0, 0, 0)

    100%
        transform: translate3d(var(--move-final-title), 0, 0)

@keyframes marquee-artist
    0%
        transform: translate3d(0, 0, 0)

    100%
        transform: translate3d(var(--move-final-artist), 0, 0)

@keyframes marquee-album
    0%
        transform: translate3d(0, 0, 0)

    100%
        transform: translate3d(var(--move-final-album), 0, 0)

// @-moz-keyframes marquee
//         0%
//             -moz-transform: translate3d(0, 0, 0)

//         100%
//             -moz-transform: translate3d(var(--move-final), 0, 0)
</style>
