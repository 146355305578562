const setInTracklist = (state, trackId, value) => {
    const index = state.result.findIndex(resItem => resItem.id === trackId)
    if (index > -1) {
        state.result[index].inTracklist = value
    }
}

const baseState = () => {
    return {
        term: '',
        result: [],
        searching: false
    }
}

const state = baseState()

const mutations = {
    setTerm(state, value) {
        state.term = value
    },
    setResult(state, value) {
        state.result = value
    },
    reset(state) {
        state.term = ''
        state.result = []
    },
    setSearching (state, value) {
        state.searching = value
    },
    setTrackLocked (state, track) {
        const index = state.result.findIndex(resItem => resItem.id === track.id)
        if (index > -1) {
            state.result[index].locked = track.locked
        }
    },
    setTrackInTracklist (state, trackId) {
        setInTracklist(state, trackId, true)
    },
    unsetTrackInTracklist (state, trackId) {
        setInTracklist(state, trackId, false)
    }
}

const actions = {
    async lookup ({ state, commit }) {
        commit('setSearching', true)
        try {
            const { data } = await this._vm.axios.get(`library/searchTracks/${state.term}`)
            if (!data.reasons) {
                commit('setResult', data)
            }
        } catch (error) {
            console.log("Could not contact server")
        }
        commit('setSearching' , false)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
