<template>
  <div class="bg-gray-800 shadow-lg mb-4 mx-2 p-2">
    <div
      class="flex flex-col my-2"
    >
      <div
        class ="flex justify-between items-center cursor-pointer border-b-2 border-gray-900 pb-2"
        @click="toggleShowTracks()"
      >
        <div class="ml-1 text-green-spotify">
          <fa-icon
            v-if="playlist.uri"
            :icon="['fab', 'spotify']"
            title="Spotify-Playlist"
          />
        </div>
        <div class="mx-2">
          <p v-if="!editing" class="font-semibold">{{ playlist.name }}</p>
          <div
            v-else
            class="flex flex-row"
          >
            <input
              id="playlist-name-input"
              class="input-main"
              v-model="newPlaylistName"
              placeholder="Playlistnamen eingeben"
              type="text"
              @click.stop
              @keyup.enter="savePlaylistName()"
              @keyup.esc="abortEditPlaylistName()"
            />
            <button
              class="button transparent button-icon flex ml-1"
              @click="savePlaylistName()"
              title="Playlist speichern"
            >
              <fa-icon icon="save" />
            </button>
            <button
              class="button transparent button-icon flex my-1"
              @click.stop="abortEditPlaylistName()"
            >
              <fa-icon icon="times" />
            </button>
          </div>
        </div>
        <span class="mx-1">
          <fa-icon
            :icon="currentPlaylist === playlist.id ? 'chevron-up': 'chevron-down'"
            :title="currentPlaylist === playlist.id ? 'Songs ausblenden' : 'Songs einblenden'"
          />
        </span>
      </div>
      <div class="mx-auto inline-flex mt-2">
        <p>{{ playlist.tracks.length +
          `${playlist.tracks.length === 1 ? ' Song' : ' Songs'}`}}
        </p>
        <p class="mx-4" v-if="playlist.length">
          &mdash;
        </p>
        <p v-if="playlist.length">{{ toDuration(playlist.length) }} Spielzeit</p>

      </div>
      <div class="inline-flex justify-center">
        <button
          class="button transparent button-icon"
          title="Abspielen"
          @click.stop="deleteMenu = false; abortSyncFromSpotify(); abortEditPlaylistName(); shuffleMenu = false; playMenu = true;"
        >
          <fa-icon icon="play" />
        </button>
        <button
          class="button transparent button-icon"
          title="Shuffle"
          @click.stop="deleteMenu = false; abortSyncFromSpotify(); abortEditPlaylistName(); shuffleMenu = true; playMenu = false;"
        >
          <fa-icon icon="random" />
        </button>
        <button
          class="button transparent button-icon ml-2"
          title="Playlist umbenennen"
          @click.stop="deleteMenu = false; abortSyncFromSpotify(); editPlaylistName(); shuffleMenu = false; playMenu = false;"
        >
          <fa-icon icon="edit" />
        </button>
        <button
          class="button transparent button-icon ml-2"
          title="Playlist mit Spotify synchronisieren"
          @click.stop="deleteMenu = false; syncFromSpotify(); abortEditPlaylistName(); shuffleMenu = false; playMenu = false;"
          v-if="playlist.uri"
        >
          <fa-icon icon="sync-alt" />
        </button>
        <button
          class="button transparent button-icon ml-2"
          title="Playlist löschen"
          @click.stop="deleteMenu = true; abortSyncFromSpotify(); abortEditPlaylistName(); shuffleMenu = false; playMenu = false;"
        >
          <fa-icon icon="trash" />
        </button>
      </div>
    </div>
    <!-- menus -->
    <div class="flex flex-col px-16">
      <!-- play menu -->
      <loader v-show="syncingSpotify"
        loading-text="Syncronisiere mit Spotify"
      />
      <template v-if="playMenu">
        <button
          class="button teal my-1 w-full"
          @click="setAsTracklist()"
          title="Als aktuelle Tracklist übernehmen"
        >
          Abspielen
        </button>
        <button
          class="button teal my-1 w-full"
          @click="appendToTracklist()"
          title="An aktuelle Tracklist anhängen"
        >
          Anhängen
        </button>
        <button
          class="button gray my-1 w-full"
          @click="playMenu = false"
          title="Abbrechen"
        >
          Abbrechen
        </button>
      </template>
      <!-- shuffle menu -->
      <template v-if="shuffleMenu">
        <button
          class="button teal my-1 w-full"
          @click="setAsTracklist(true)"
          title="Als aktuelle Tracklist in zufälliger Reihenfolge übernehmen"
        >
          Zufällig abspielen
        </button>
        <button
          class="button teal my-1 w-full"
          @click="appendToTracklist(true)"
          title="An aktuelle Tracklist in zufälliger Reihenfole anhängen"
        >
          Zufällig anhängen
        </button>
        <button
          class="button gray my-1 w-full"
          @click="shuffleMenu = false"
          title="Abbrechen"
        >
          Abbrechen
        </button>
      </template>
      <!-- delete menu -->
      <template v-if="deleteMenu">
        <button
            class="button red my-1 w-full"
            @click="deletePlaylist()"
            title="Playlist aus Datenbank entfernen"
          >
          Löschen!
        </button>
        <button
          class="button gray my-1 w-full"
          @click="deleteMenu = false"
          title="Abbrechen"
        >
          Abbrechen
        </button>
      </template>
      <!-- track sync menu -->
      <template v-if="syncTracksDiff.length > 0">
        <button
          class="button teal my-1 w-full"
          @click="newSyncPlaylist()"
        >
          Als neue Playlist speichern
        </button>
        <button
          class="button teal my-1 w-full"
          @click="submitSyncFromSpotify()"
        >
          Playlist aktualisieren
        </button>
        <button
          class="button gray my-1 w-full"
          @click="abortSyncFromSpotify()"
        >
          Abbrechen
        </button>
      </template>
    </div>

    <!-- list of tracks -->
    <div v-if="loadingTracks" class="flex justify-center items-center">
      <loader loading-text="Lade Songs" />
    </div>
    <div
      v-else-if="currentPlaylist === playlist.id && tracks.length > 0"
      class="flex flex-col mb-2"
    >
      <div
        v-for="track in tracks"
        :key="`track-${track.id}`"
        class="flex flex-col sm:flex-row flex-no-wrap justify-between mt-2 pl-2 py-1 pr-1 items-center bg-gray-700 rounded-sm"
      >
        <track-card
          :track="track"
          size="sm"
        />
        <div class="mt-2 ml-0 sm:mt-0 sm:flex sm:flex-col">
          <button
            v-if="voteable"
            class="button transparent button-icon"
            title="Zur Abstimmung hinzufügen"
            @click="addToTracklist(track.id)"
          >
            <fa-icon icon="thumbs-up" />
          </button>
          <button
            class="button transparent button-icon"
            title="Song aus Playlist entfernen"
            @click="deleteTrackFromPlaylist(track.id)"
          >
            <p v-if="confirmDeleteTrackId === track.id">
              Sicher löschen?
            </p>
            <fa-icon icon="trash" />
          </button>
        </div>
      </div>
    </div>
    <p v-else-if="currentPlaylist === playlist.id && tracks.length === 0" class="italic text-center mb-2">
      Keine Songs in der Playlist
    </p>
  </div>
</template>

<script>
import TrackCard from './TrackCard.vue'
import { mapState } from 'vuex'
import timeFormatter from '../mixins/timeFormatter'
import Vue from "vue"

export default {
  components: {
    TrackCard
  },
  mixins: [ timeFormatter ],
  watch: {
    savingTracks (oldVal, newVal) {
      if (oldVal && !newVal) {
        this.syncTracksDiff = []
      }
    }
  },
  props: {
    playlist: {
      type: Object
    },
  },
  computed: {
    ...mapState({
      savingTracks: state => state.playlist.savingTracks,
      reasons: state => state.playlist.reasons,
      tracklistTracks: state => state.tracklist.tracks,
      voteable: state => state.admin.voteable,
      currentPlaylist: state => state.playlist.currentPlaylist
    })
  },
  data () {
    return {
      tracks: [],
      loadingTracks: false,
      editing: false,
      playMenu: false,
      shuffleMenu: false,
      deleteMenu: false,
      newPlaylistName: '',
      syncingSpotify: false,
      syncTracksDiff: [],
      confirmDeleteTrackId: null,
      confirmDelete: false
    }
  },
  methods: {
    addToTracklist (trackId) {
      const index = this.tracklistTracks.findIndex(track => track.id === trackId)

      if (index > -1) {
        const track = this.tracklistTracks[index]
        this.$notify(`${track.name} ist bereits in Tracklist auf Rang ${index + 1}`, {
          type: 'success'
        })
      } else {
        const track = this.tracks.find(track => track.id === trackId)
        this.$notify('Song hinzugefügt', {
          title: track.name,
          type: 'success'
        })
      }
      this.$store.dispatch('tracklist/vote', [trackId])
    },
    async appendToTracklist (random = false) {
      const { data } = await this.axios.put('tracklist/append', { playlistId: this.playlist.id, random })

      if (!data.reasons) {
        this.$notify('Songs hinzugefügt', {
          title: this.playlist.name,
          type: 'success'
        })
      } else {
        this.$notify(`Songs nicht hinzugefügt`, {
          title: 'Fehler',
          type: 'error'
        })
      }
    },
    toggleShowTracks () {
      if (this.currentPlaylist === this.playlist.id) {
        this.$store.commit("playlist/setCurrentPlaylist", null);
      } else {
        this.$store.commit("playlist/setCurrentPlaylist", this.playlist.id);
        this.loadTracks();
      }
    },
    async loadTracks () {
      this.loadingTracks = true
      const { data } = await this.axios.get(`playlist/${this.playlist.id}`)

      if (!data.reasons) {
        this.tracks = data.tracks
      }
      this.loadingTracks = false
    },
    deleteTrackFromPlaylist (trackId) {
      if (this.confirmDeleteTrackId === trackId) {
        const index = this.tracks.findIndex(track => track.id == trackId)

        if (index > -1) {
          this.tracks.splice(index, 1)
          const trackIds = this.tracks.map(track => track.id)
          this.$store.dispatch('playlist/saveTracks',
            { playlistId: this.playlist.id, trackIds })
        }
        this.confirmDeleteTrackId = null
      } else {
        this.confirmDeleteTrackId = trackId
      }
    },
    savePlaylistName () {
      if (this.newPlaylistName.length < 2) return
      this.$store.dispatch('playlist/rename',
        { playlistId: this.playlist.id, name: this.newPlaylistName })
      this.newPlaylistName = ''
      this.editing = false;
    },
    editPlaylistName () {
      if (!this.editing) {
        this.editing = true;
        this.newPlaylistName = this.playlist.name;
        Vue.nextTick(() => {
          document.getElementById("playlist-name-input").focus()
        });

      }
    },
    abortEditPlaylistName() {
      if(this.editing) {
        this.editing = false;
        this.newPlaylistName = '';
      }
    },
    deletePlaylist () {
      if (!this.deleteMenu) {
        return;
      }
      this.$store.dispatch('playlist/delete', this.playlist.id)
      this.deleteMenu = false;
    },
    async syncFromSpotify () {
      this.syncingSpotify = true
      const { data } = await this.axios.get(`playlist/sync/${this.playlist.id}`)
      this.syncingSpotify = false

      if (!data.reasons) {
        // if playlists differ, data is list of track objects
        if (Array.isArray(data)) {
          this.syncTracksDiff = data
          this.$notify('Playlist auf Spotify aktualisiert', {
            title: "Playlist überschreiben?",
            type: 'warning'
          })
        } else {
          this.$notify('Playlist auf Spotify unverändert', {
            title: 'Synchronisiert',
            type: 'success'
          })
          this.syncTracksDiff = []
        }
      }
    },
    newSyncPlaylist (currentName) {
      const trackIds = this.syncTracksDiff.map(track => track.id)
      this.$store.dispatch('playlist/new',
        { name: `${currentName}-neu`, trackIds })
    },
    submitSyncFromSpotify () {
      const trackIds = this.syncTracksDiff.map(track => track.id)
      this.$store.dispatch('playlist/saveTracks',
        { playlistId: this.playlist.id, trackIds })
    },
    abortSyncFromSpotify () {
      this.syncTracksDiff = []
    },
    async setAsTracklist (random = false) {
      const { data } = await this.axios.put('tracklist/clear')
      if (!data.reasons) {
        const appendResult = await this.axios.put('tracklist/append', { playlistId: this.playlist.id, random })
        if (appendResult.data && !appendResult.data.reasons) {
          this.$notify(`Tracklist wurde geleert und Playlist eingesetzt`, {
            title: 'Tracklist ersetzt',
            type: 'success'
          })

          this.$store.dispatch('tracklist/load', {})
          this.$store.dispatch('player/loadStatus')
        } else {
          this.$notify(`Playlist konnte nicht angehängt werden: ${appendResult.data.reasons}`, {
            title: 'Fehler',
            type: 'error'
          })
        }
      } else {
        this.$notify(`Aktuelle Abstimmung konnte nicht geleert werden: ${data.reasons}`, {
          title: 'Fehler',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
