<template>
   <div class="max-w-lg mx-auto">
      <div class="flex justify-between content-center mt-2">
         <div class="sort-menu flex flex-no-wrap font-semibold items-center justify-center">
            <div class="flex items-center justify-center">
               <span class="flex flex-no-wrap" :class="{ active: sortBy === 'name' }" @click="sort('name')">
                  <p>Titel</p>
                  <fa-icon v-if="sortBy === 'name' && order === 1" class="ml-2" icon="chevron-up" />
                  <fa-icon v-if="sortBy === 'name' && order !== 1" class="ml-2" icon="chevron-down" />
               </span>
               <span class="flex flex-no-wrap" :class="{ active: sortBy === 'artist' }" @click="sort('artist')">
                  <p>Interpret</p>
                  <fa-icon v-if="sortBy === 'artist' && order === 1" class="ml-2" icon="chevron-up" />
                  <fa-icon v-if="sortBy === 'artist' && order !== 1" class="ml-2" icon="chevron-down" />
               </span>
            </div>
         </div>
         <button
            type="button"
            class="button-text underline"
            @click="$router.push('/lockArtist')"
         >
            Künstler sperren
            <fa-icon class="ml-2" icon="arrow-right" />
         </button>
      </div>
      <div
         v-for="(track, i) in lockedTracks"
         :key="`track-${track.id}`"
         class="mt-2"
      >
         <div v-if="sortBy !== '' && newFirstCharacter(i) !== ''" class="horizontal-separator" :key="`separator-${i}`">
            <span />
            <p>{{newFirstCharacter(i)}}</p>
            <span />
         </div>
         <div class="flex flex-no-wrap justify-between items-center p-2 bg-gray-800">
            <track-card
               :track="track"
               size="sm"
               :titleStyle="[ 'tracking-wide', 'font-semibold', 'text-white' ]"
               :artistStyle="[ 'text-sm', 'text-white' ]"
               :albumStyle="[ 'text-sm', 'text-white' ]"
            />
            <button
               v-if="isAdmin"
               class="button transparent button-icon"
               @click="unlock(track.id)"
               title="Song entsperren">
               <fa-icon icon="unlock" />
            </button>
         </div>
      </div>
      <div class="flex mt-2 flex-col px-2">
         <p v-if="lockedTracks.length === 0 && !loading" class="italic text-center">Aktuell ist kein Song gesperrt!</p>
         <p
            v-if="isAdmin"
            class="mt-2 text-center"
         >
            Zum Sperren eines Songs kannst Du nach ihm suchen und das Schloss-Symbol benutzen.
         </p>
      </div>
   </div>
</template>

<script>
import TrackCard from '../components/TrackCard.vue'
import { mapState } from "vuex"

export default {
      components: { TrackCard },
      data () {
         return {
            lockedTracks: [],
            sortBy: '',
            order: 1,
            loading: false
         }
      },
      mounted () {
         this.loadLockedTracks()
      },
      computed: {
         ...mapState({
               isAdmin: state => state.user.isAdmin
         })
      },
      methods: {
         async loadLockedTracks () {
            this.loading = true;
            const { data } = await this.axios.get('library/lockedTracks')

            if (!data.reasons) {
               this.lockedTracks = data
            }
            this.loading = false;
         },
         async unlock (trackId) {
            const { data } = await this.axios.put('library/lockTrack',
               { trackId, locked: false })

            if (!data.reasons) {
               this.loadLockedTracks()
            } else {
               console.log('data ging was schief: ', data.reasons)
            }
         },
         sort (by) {
            if (this.sortBy === by) this.order *= -1
            this.sortBy = by

            this.lockedTracks.sort((a, b) => {
               if (by === 'name') {
               return a.name < b.name ? -this.order : this.order
               } else if (by === 'artist') {
               return a.artists[0] < b.artists[0] ? -this.order : this.order
               }
            })
         },
         newFirstCharacter (index) {
            let currentChar = ''
            let prevChar = ''
            if (this.sortBy === 'artist') {
               currentChar = this.lockedTracks[index].artists[0].charAt(0)
               prevChar = index > 0 ? this.lockedTracks[index - 1].artists[0].charAt(0) : ''
            } else {
               currentChar = this.lockedTracks[index].name.charAt(0)
               prevChar = index > 0 ? this.lockedTracks[index - 1].name.charAt(0) : ''
            }

            if (currentChar !== prevChar) {
               return currentChar
            }
            else return ''
         }
      }
}
</script>

<style lang="sass" scoped>
.track-container
   @apply

.sort-menu

   span
      @apply w-32 rounded-full flex inline-flex items-center justify-center px-3 py-1 ml-1 cursor-pointer bg-gray-200 text-gray-800

      &:hover
         @apply bg-gray-400 text-gray-900

      &.active
         @apply bg-green-500 text-white
</style>
