<template>
  <div class="relative mx-auto w-80 sm:w-128">
    <div>
      <input type="text" v-model="searchTerm" class="input-main w-full mt-2" placeholder="Nach Account suchen ..." />
    </div>

    <div class="horizontal-separator">
      <span />
      <p>Admins</p>
      <span />
    </div>
    <!-- create new admin -->
    <button
      class="button gray w-full"
      @click="createNewSession()"
    >
      Neuen Admin erstellen
    </button>
    <!-- admins -->
    <div
      v-for="admin in admins"
      :key="`admin-${admin.id}`"
      class="flex flex-col"
    >
      <session-profile
        :id="admin.sessionId"
        :name="admin.name"
        :last-seen="admin.last_seen"
      />
    </div>
    <!-- sessions -->
    <div class="horizontal-separator">
      <span />
      <p>Sessions</p>
      <span />
    </div>
    <div
      v-for="session in sessions"
      :key="`session-${session.sessionId}`"
      class="flex flex-col"
    >
      <session-profile
        :id="session.sessionId"
        :name="session.name"
        :last-seen="session.last_seen"
      />
    </div>
    <session-editor
      v-if="editingSessionId > -2"
      :isAdmin="editingAdmin"
      :session="getSession(editingSessionId)"
      @close="editingSessionId = -2"
      @delete="deleteSession()"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SessionProfile from '../components/SessionProfile.vue'
import SessionEditor from '../components/SessionEditor.vue'

export default {
  components: { SessionProfile, SessionEditor },
  computed: {
    ...mapState({
      allSessions: state => state.admin.sessions,
      allAdmins: state => state.admin.admins,
      profileImages: state => state.user.sessionImages,
      editingSessionId: state => state.user.editingSessionId
    }),
    sessions () {
      return this.allSessions.filter(session => this.search(session))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    },
    admins () {
      return this.allAdmins.filter(admin => this.search(admin))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    }
  },
  data () {
    return {
      editingAdmin: false,
      searchTerm: ''
    }
  },
  async mounted () {
    await this.$store.dispatch('admin/loadSessions')

    const sessionIds = this.allSessions.map(s => s.sessionId)
      .concat(this.allAdmins
        .reduce((res, acc) => {
          if (acc.sessionId) res.push(acc.sessionId)
          return res
        }, []))

    this.$store.dispatch('user/loadSessionImages', sessionIds)
  },
  methods: {
    createNewSession () {
      if (this.editingSessionId === -2) {
        this.$store.commit('user/setEditingSessionId', -1)
      }
    },
    deleteSession () {
      console.log('event emitted!')
    },
    search (el) {
      if (this.searchTerm === '') return true
        const searchRegex = new RegExp(this.searchTerm.trim().toLowerCase(), 'g')
        return el.name.toLowerCase().search(searchRegex) > -1
    },
    getSession (id) {
      if (id === -1) {
        this.editingAdmin = true
        return { id: -1 }
      }
      const admin = this.admins.find(admin => admin.sessionId === id)
      if (admin) {
        this.editingAdmin = true
        return admin
      } else {
        this.editingAdmin = false
        return this.sessions.find(session => session.sessionId === id)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.session
  @apply flex flex-no-wrap justify-between mt-2 p-2 bg-green-300

  &.self
    @apply bg-green-400

  .profile
    @apply flex-1 flex flex-wrap

  .actions
    @apply flex-none inline-flex items-center

.sort-tabs
  @apply flex flex-no-wrap bg-blue-200 text-indigo-700 cursor-pointer font-semibold

  div
    @apply flex-1 flex inline-flex items-center justify-center  p-3

    &:first-child
      @apply border-r-2 border-indigo-900

    &:last-child
      @apply border-l-2 border-indigo-900

    &:hover
      @apply bg-blue-400 text-indigo-900

    &.active
      @apply bg-blue-400 text-indigo-900
</style>