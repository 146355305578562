import Vue from 'vue'
import Vuex from 'vuex'
import admin from './admin'
import player from './player'
import playlist from './playlist'
import tracklist from './tracklist'
import search from './search'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    player,
    playlist,
    tracklist,
    search,
    user
  },
  // don't run strict mode in production! Deep watcher is used!
  strict: process.env.NODE_ENV !== 'production',
  plugins: []
})