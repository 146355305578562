<template>
    <div class="mx-auto sm:mt-20 max-w-md shadow-lg rounded px-8 pt-6 pb-8">
        <p class="text-center text-2xl">Willkommen in der Hitbox!</p>
        <div class="my-4 mx-auto w-20">
            <img src="../assets/image/logoWhite.svg" />
        </div>
        <div class="w-full h-80 bg-gray-200 overflow-hidden flex items-center justify-center mt-2 bg-circuit border-2 border-gray-400">
            <img
                v-if="!user.sessionId[user.sessionId]"
                :src="user.sessionImages[user.sessionId]"
            />
            <loader
                v-else
                loadingText="Lade Profilbild"
            />
        </div>
        <p class="text-center text-3xl my-1">
            Bist du das?
        </p>
        <button
            class="button red mt-2 w-full"
            @click="$router.push({ name: 'profile', params: { openDialog: true } })"
        >
            Nein
        </button>
        <button
            class="button green mt-2 w-full"
            @click="continueRoute()"
        >
            Ja
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            user: state => state.user
        })
    },
    data () {
        return {

        }
    },

    mounted() {
        if(!this.user.wasLogin) {
            this.$router.push('/');
        } else if(!this.user.imageExisted) {
            this.$router.push('welcome');
        }
    },

    methods: {
        continueRoute () {
            this.$store.commit('user/resetImageExisted')
            if (this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect)
            } else {
                this.$router.push('/')
            }
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
