import Vue from "vue";

let debounce = null

const baseState = () => ({
    // playing, paused, stopped, error oder disconnected
    // für user: error und disconnected als stopped darstellen
    status: '',
    current: null,
    currentTrackImage: null,
    currentTrackVotes: [],
    next: null,
    nextTrackImage: null,
    nextTrackVotes: [],
    loading: false,
    cherryPick: null,
    volume: 0,
    elapsed: null
})

const state = baseState()

const mutations = {
    setStatus (state, value) {
        state.status = value
    },
    resetStatus (state) {
        state.status = ''
    },
    setLoading (state) {
        state.loading = true
    },
    resetLoading (state) {
        state.loading = false
    },
    setCurrent (state, track) {
        state.current = track
    },
    resetCurrent (state) {
        state.current = null
    },
    setCurrentTrackImage (state, currentTrackImage) {
        state.currentTrackImage = currentTrackImage
    },
    resetCurrentTrackImage (state) {
        state.currentTrackImage = null
    },
    setCurrentTrackVotes (state, currentTrackVotes) {
        state.currentTrackVotes = currentTrackVotes
    },
    resetCurrentTrackVotes(state) {
        state.currentTrackVotes = []
    },
    setNext (state, track) {
        state.next = track
    },
    resetNext (state) {
        state.next = null
    },
    setNextTrackImage (state, nextTrackImage) {
        state.nextTrackImage = nextTrackImage
    },
    resetNextTrackImage (state) {
        state.nextTrackImage = null
    },
    setNextTrackVotes (state, nextTrackVotes) {
        state.nextTrackVotes = nextTrackVotes
    },
    resetNextTrackVotes(state) {
        state.nextTrackVotes = []
    },
    setCherryPick(state, cherryPick) {
        state.cherryPick = cherryPick;
    },
    resetCherryPick(state) {
        state.cherryPick = null;
    },
    setVolume(state, volume) {
        state.volume = volume
    },
    setElapsed(state, seconds) {
        state.elapsed = seconds
    },
    resetElapsed(state) {
        state.elapsed = null
    },
    // lockVolume(state) {
    //     state.volumeLocked = true
    // },
    // unlockVolume(state) {
    //     state.volumeLocked = false
    // },
    resetVolume(state) {
        state.volume = 0
    }
}

const actions = {
    async loadStatus ({ commit }, isTheater = false) {
        commit('setLoading')
        // get current and next = CAN
        try {
            const { data } = await this._vm.axios.get('tracklist/can' + (isTheater ? "/theater" : ""))
            if (!data.reasons) {
                if (data.state) {
                    commit('setStatus', data.state)
                } else {
                    commit('setStatus', 'server error')
                }
                if (data.current) {
                    commit('setCurrent', data.current)
                } else {
                    commit('resetCurrent')
                }
                if (data.currentTrackImage) {
                    commit('setCurrentTrackImage', data.currentTrackImage)
                } else {
                    commit('resetCurrentTrackImage')
                }
                if (data.currentTrackVotes) {
                    commit('setCurrentTrackVotes', data.currentTrackVotes) 
                } else {
                    commit('resetCurrentTrackVotes')
                }
                if (data.next) {
                    commit('setNext', data.next)
                } else {
                    commit('resetNext')
                }
                if (data.nextTrackImage) {
                    commit('setNextTrackImage', data.nextTrackImage)
                } else {
                    commit('resetNextTrackImage')
                }
                if (data.nextTrackVotes) {
                    commit('setNextTrackVotes', data.nextTrackVotes) 
                } else {
                    commit('resetNextTrackVotes')
                }
                if (data.cherryPick) {
                    commit('setCherryPick', data.cherryPick);
                } else {
                    commit('resetCherryPick');
                }
                if (data.volume) {
                    commit('setVolume', data.volume);
                } else {
                    commit('resetVolume');
                }
                if (data.elapsed) {
                    commit('setElapsed', data.elapsed)
                } else {
                    commit('resetElapsed')
                }
            } 
        } catch(error) {
            console.log(error)
        }
        commit('resetLoading')

    },
    async command ({ commit, dispatch }, command) {
        const { data } = await this._vm.axios.put('command', { command })

        if (!data.reasons) {
            // load (new) status after command execution
            dispatch('loadStatus')
        } else {
            if (data.reasons.includes('disconnected')) {
                commit('setStatus', 'disconnected')
                dispatch('poll')
            } else {
                console.log('da ist was schief gelaufen: ', data.reasons)
            }
        }
    },
    async cherryPick({ dispatch }, trackId) {
        const { data } = await this._vm.axios.put('tracklist/cherryPick', { trackId: trackId });
        if(!data.reasons) {
            Vue.notify('CherryPick erfolgreich gewählt', {
                type: 'success'
            });
            dispatch('loadStatus');
        } else {
            Vue.notify('CherryPick konnte nicht gewählt werden', {
                type: 'error'
            });
        }
    },
    async resetCherryPick({ commit }) {
        const { data } = await this._vm.axios.put('tracklist/cherryPick', { trackId: -1 });
        if(!data.reasons) {
            Vue.notify('CherryPick gelöscht', {
                type: 'success'
            });
            commit('resetCherryPick');
        } else {
            Vue.notify('CherryPick konnte nicht gelöscht werden', {
                type: 'error'
            });
        }
    },
    poll ({ state, dispatch }, isTheater = false) {
        const ms = isTheater ? 500 : 5000
        if (debounce) {
            clearInterval(debounce)
            debounce = null
        }

        debounce = setInterval(() => {
            if (!state.loading) {
                dispatch('loadStatus', isTheater)
            }
        }, ms)
    },
    stopPoll () {
        if (debounce) {
            clearInterval(debounce)
            debounce = null
        }
    },
    async setVolume({ commit }, volume) {
        commit('setVolume', volume)

        const { data } = await this._vm.axios.put('volume', { volume })
        console.log('data: ', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
