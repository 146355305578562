<template>
    <div class="flex justify-center overflow-hidden">
        <div class="flex flex-col">
            <div v-for="(artist, i) in artists"
                :key="`artist-${i}`"
            >
                <div
                    v-if="i === 0 || artists[i - 1].count > artist.count"
                    class="horizontal-separator"
                >
                    <span/>
                    <p class="font-mono">{{ pre00(artist.count) }}</p>
                    <span/>
                </div>
                <div class="flex flex-no-wrap justify-between py-2 px-4 bg-gray-800 mt-2">
                    <div class="inline-flex items-center">
                        <span v-if="i === 0" class="text-yellow-600 mr-2">
                            <fa-icon icon="crown" />
                        </span>
                        <p class="headline" style="width: 300px">{{ artist.artist }}</p>
                    </div>
                    <p class="w-10 pl-2 text-left font-mono tracking-wide text-lg md:text-xl lg:text-2xl font-semibold">{{ pre00(artist.count) }}</p>
                </div>
            </div>
            <div class="mt-2">
                <button v-if="artists.length > 0" class="button green w-full"
                    @click="$emit('load-more')"
                >
                    Mehr Laden
                </button>
                <p v-else>
                    Keine Künstler im gewählten Zeitraum
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import numberFormatter from '../mixins/numberFormatter'

export default {
    mixins: [ numberFormatter ],
    props: {
        artists: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
