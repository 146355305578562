<template>
    <div
        id="artist-search"
        class="relative w-full max-w-md sm:max-w-lg mx-auto sm:flex-auto"
    >
        <div class="flex bg-white">
            <input
                type="text"
                id="artistSearchInput"
                v-model="searchTerm"
                placeholder="Künstler suchen..."
                class="input-main w-full"
                :disabled="loading"
                :class="loading ? ['disabled placeholder-red-900'] : null"
                @input="delayLookup()"
                @keyup.enter="lookup(true)"
                @keyup.esc="exitSearch()"
                @focus="initSearch()"
            />
            <div class="w-auto flex justify-end items-center p-1">
                <loader
                    v-show="loading"
                    theme="light"
                    class="mr-2"
                />
                <button
                    v-if="isSearchActive()"
                    class="button transparent black button-icon"
                    title="Suche schließen"
                    @click="resetSearch()"
                >
                    <fa-icon icon="times" />
                </button>
                <button
                    v-else
                    class="button transparent black button-icon"
                    @click="focusArtistSearch()"
                >
                    <fa-icon icon="search" />
                </button>
            </div>
        </div>
        <div v-if="searchTerm !== '' && artists.length > 0" class="horizontal-separator">
            <span />
            <p>{{searchTerm !== '' ? 'Suchergebnisse' : 'Gesperrte Künstler'}}</p>
            <span />
        </div>
        <p v-else class="mt-2 text-center">
            Suche einen Künstler, um ihn zu sperren
        </p>
        <div
            v-for="artist in artists"
            :key="`artist-${artist.id}`"
            class="w-full bg-gray-800 h-12 px-2 mt-2 flex justify-between items-center"
        >
            <p>{{artist.name}}</p>
            <loader
                v-if="lockingArtist(artist.id)"
                theme="dark"
            />
            <button
                v-else
                class="button-icon"
                :title="artist.locked ? 'entsperren': 'sperren'"
                @click="lockArtist(artist)"
            >
                <fa-icon :icon="artist.locked ? 'lock' : 'unlock'" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            searchTerm: '',
            loading: false,
            delayRequest: null,
            artists: [],
            lockingArtists: []
        }
    },
    async mounted () {
        window.addEventListener('click', () => {
            if (this.searchResultVisible) {
                if (this.searchTerm.length === 0) {
                    this.resetSearch()
                }
                this.searchResultVisible = false
            }
        })
        this.fetchLockedArtists()
        // document.getElementById('artist-search').addEventListener('click', event => {
        //     event.stopPropagation()
        // })
    },
    methods: {
        async fetchLockedArtists () {
            const { data } = await this.axios.get('library/lockedArtists')
            if (!data.reasons) {
                this.artists = data
            } else {
                console.log('Erorr while loading locked artists: ', data.reasons)
            }
        },
        initSearch () {
            this.searchResultVisible = true
        },
        focusArtistSearch () {
            document.getElementById('artistSearchInput').focus()
        },
        delayLookup () {
            if (this.delayRequest) clearTimeout(this.delayRequest)

            this.delayRequest = setTimeout(() => {
                this.lookup()
            }, 1500)
        },
        async lookup (blur = false) {
            this.loading = true
            if (this.delayRequest) {
                clearTimeout(this.delayRequest)
                this.delayRequest = null
            }
            if (this.searchTerm.length > 1) {
                const { data } = await this.axios.get(`library/searchArtists/${this.searchTerm}`)
                if (!data.reasons) {
                    this.artists = data
                }
                if (blur) {
                    this.getArtistSearchInput().blur()
                }
            } else if (this.searchTerm.length === 1) {
                this.$notify('Bitte mindestens zwei Zeichen eingeben', {
                type: 'warning'
                })
            } else {
                this.resetSearch()
            }
            this.loading = false
        },
        getArtistSearchInput() {
            return document.getElementById('artistSearchInput');
        },
        isSearchActive() {
            return this.searchTerm.length > 0 || (document.activeElement == this.getArtistSearchInput());
        },
        exitSearch() {
            this.resetSearch()
            this.getArtistSearchInput().blur()
        },
        resetSearch () {
            this.searchTerm = ''
            this.fetchLockedArtists()
        },
        async lockArtist (artist) {
            this.lockingArtists.push(artist.id)
            const result = await this.axios.put('library/lockArtist', { artistId: artist.id, locked: !artist.locked })
            if (result.reasons) {
                console.log(`Error locking artist (id: ${artist.id}): `, result.reasons)
            }
            this.lockingArtists = this.lockingArtists.filter(id => id !== artist.id)
            console.log('asdf ', this.lockingArtists)
            this.resetSearch()
        },
        lockingArtist (id) {
            return this.lockingArtists.includes(id)
        }
    }
}
</script>
