<template>
    <div class="relative">
        <div class="rounded-full border-2 flex items-center 
                justify-center overflow-hidden transition duration-500"
            :class="[imgSize, themedProfileColors]"
        >
            <img v-if="profileImages[voter.id]"
                :src="profileImages[voter.id]"
                class="w-full h-full"
                alt="Bild nicht geladen" />
            <fa-icon v-else icon="user-tie" class="text-white" />
        </div>
        <span v-if="voter.count > 1"
            class="absolute right-0 top-0 font-bold rounded-full
                flex items-center justify-center transform"
            :class="[voteCountSize, translateVoteCount, voteTextSize, themedVoteCountColors]"
        >
            <p>{{ voter.count }}</p>
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
        voter: {
            type: Object,
            required: true
        },
        size: {
            type: String,
            default: 'base',
            validator (value) {
                return ['sm', 'base', 'lg'].includes(value)
            }
        },
        theme: {
            type: String,
            default: 'dark',
            validator (value) {
                return ['light', 'dark'].includes(value)
            }
        }
    },
    computed: {
        ...mapState({
            profileImages: state => state.user.sessionImages
        }),
        imgSize () {
            if (this.size === 'sm') return 'w-12 h-12'
            else if (this.size === 'lg') return 'w-24 h-24'
            return 'w-16 h-16'
        },
        voteCountSize() {
            if (this.size === 'sm') return 'w-6 h-6'
            else if (this.size === 'lg') return 'w-12 h-12'
            return 'w-8 h-8'
        },
        translateVoteCount() {
            if (this.size === 'sm') return 'translate-x-1 -translate-y-2'
            if (this.size === 'lg') return 'translate-x-2 -translate-y-2'
            return 'translate-x-2 -translate-y-1'
        },
        voteTextSize() {
            if (this.size === 'sm') return 'text-base'
            return 'text-lg'
        },
        themedProfileColors() {
            if (this.theme === 'light') return 'border-black bg-white text-black'
            return 'border-white bg-gray-800 text-white'
        },
        themedVoteCountColors() {
            if (this.theme === 'light') return 'bg-gray-800 text-white'
            return 'bg-white text-black'
        }
    },
    data() {
        return {

        }
    },
    async beforeMount() {
        if (this.profileImages[this.voter.id] === undefined) {
            await this.$store.dispatch('user/loadSessionImages', [this.voter.id])
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
