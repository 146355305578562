<template>
  <div
    v-if="user.loggedIn"
    class="relative"
  >
    <div class="flex flex-no-wrap items-center justify-end">
      <div
        class="h-12 w-12 border-2 border-gray-100 bg-gray-700 rounded-full text-center overflow-hidden cursor-pointer flex items-center justify-center hover-profile"
        :title="user.sessionImages[user.sessionId] ? undefined : 'Profilbild aufnehmen'"
        @click="$router.wrapPush('/profile')"
      >
        <img
          v-if="user.sessionImages[user.sessionId]"
          :src="user.sessionImages[user.sessionId]"
          alt="Bild nicht geladen"
        />
        <fa-icon
          v-else
          icon="user-tie"
          class=""
        />
      </div>
      <p class="hidden md:block text-lg text-center mx-2 truncate w-20 lg:w-32">{{ user.name }}</p>
      <button class="button transparent button-icon ml-2 md:ml-0" @click.stop="userMenuVisible = !userMenuVisible">
        <fa-icon icon="bars" />
      </button>
    </div>
    <div
      v-show="userMenuVisible"
      class="user-menu transform translate-y-1 -translate-x-28 md:-translate-x-6 lg:translate-x-6"
    >
      <div
        class="user-menu-option"
        @click="goTo('/')"
      >
        <fa-icon icon="music" />
        <p class="ml-2">Abstimmung</p>
      </div>
      <div
        v-if="user.isAdmin"
        class="user-menu-option"
        @click="goTo('/lockedMusic')"
      >
        <fa-icon icon="lock" />
        <p class="ml-2">Gesperrte Musik</p>
      </div>
      <div
        class="user-menu-option"
        @click="goTo('/stats')"
      >
        <fa-icon icon="chart-line" />
        <p class="ml-2">Statistiken</p>
      </div>
      <div
        class="user-menu-option"
        @click="goTo('/qr')"
      >
        <fa-icon icon="qrcode" />
        <p class="ml-2">QR Code</p>
      </div>
      <div
        v-if="user.isAdmin"
        class="user-menu-option"
        @click="goTo('/playlists')"
      >
        <fa-icon icon="stream" />
        <p class="ml-2">Playlists</p>
      </div>
      <div
        v-if="user.isAdmin"
        class="user-menu-option"
        @click="goTo('/accounts')"
      >
        <fa-icon icon="users-cog" />
        <p class="ml-2">Accounts</p>
      </div>
      <div
        v-if="user.isAdmin"
        class="user-menu-option"
        @click="goTo('/settings')"
      >
        <fa-icon icon="cog" />
        <p class="ml-2">Einstellungen</p>
      </div>
      <div
        v-if="user.isAdmin"
        class="user-menu-option"
        @click="goTo('/theater')"
      >
        <fa-icon icon="desktop" />
        <p class="ml-2">Theatermodus</p>
      </div>
      <div
        v-if="user.isAdmin"
        class="user-menu-option"
        @click="logout()"
      >
        <fa-icon icon="door-open" />
        <p class="ml-2">Logout</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      user: state => state.user,
    })
  },
  data () {
    return {
      userMenuVisible: false
    }
  },
  mounted () {
    window.addEventListener('click', () => {
      if (this.userMenuVisible) this.userMenuVisible = false
    })
  },
  methods: {
    goTo (route) {
      this.$router.wrapPush(route)
    },
    logout () {
      this.$store.dispatch('user/logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="sass" scoped>
.user-menu
  @apply absolute w-48 bg-gray-100 rounded z-10 text-gray-800
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.7)

  .user-menu-option
    @apply pl-1 py-1 flex flex-no-wrap items-center cursor-pointer

    >svg
      @apply w-8

    &:hover
      @apply bg-gray-400 text-gray-900

      &:first-child
        @apply rounded-t

      &:last-child
        @apply rounded-b

.hover-profile
  &:hover
    @apply text-white
</style>
