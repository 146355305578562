<template>
    <div class="grid grid-cols-1 lg:flex lg:justify-between bg-gray-800 text-white mb-2">
        <template v-if="connected">
            <div v-if="!showCurrent && isAdmin" class="flex-1 flex flex-col">
                <div v-if="hasTracks || next !== null" class="flex-1 flex justify-center items-center my-2">
                    <div class="navigator">
                        <button class="button transparent button-icon text-white"
                            @click="command('play')">
                            <fa-icon icon="play" />
                        </button>
                    </div>
                </div>
                <div v-else class="navigator my-2 mx-auto">
                    <img src="../assets/image/logoWhite.svg" class="w-12 h-12" />
                </div>
                <div v-if="!hasTracks" class="mb-1">
                    <p class="no-track" v-if="!$store.state.admin.voteable">Abstimmung
                        <button class="button-text white" @click="$router.wrapPush('/settings')">einschalten</button> oder
                        <button class="button-text white" @click="$router.wrapPush('/playlists')">Playlist abspielen</button></p>
                    <p class="no-track" v-else>Für Song
                        <button class="button-text white" @click="focusSongSearch()">abstimmen</button> oder
                        <button class="button-text white" @click="$router.wrapPush('/playlists')">Playlist abspielen</button>
                    </p>
                </div>
            </div>
            <div v-else-if="!showCurrent && !isAdmin" class="flex-1 flex justify-center items-center">
                <div class="navigator my-2">
                    <img src="../assets/image/logoWhite.svg" class="w-12 h-12" />
                </div>
            </div>
            <template v-else>
                <div class="flex-1 flex flex-col p-2">
                    <p class="ml-0 mb-2 tracking-wide text-xl font-semibold" style="font-variant: small-caps">
                        {{ status === 'playing' ? 'Jetzt spielt:' : 'Pausiert:' }}
                    </p>
                    <div class="flex justify-center">
                        <track-card
                            v-if="current"
                            :track="current"
                            size="md"
                            :autoMarquee="true"
                            coverSize="md"
                            :titleStyle="[ 'tracking-wide', 'font-semibold', 'text-green-300' ]"
                        />
                    </div>
                </div>
                <div class="flex-1 flex justify-center items-center">
                    <div class="navigator">
                        <button v-if="showPlayButton" class="button transparent button-icon text-white"
                            @click="command('play')">
                            <fa-icon icon="play" />
                        </button>
                        <button v-if="showPauseButton" class="button transparent button-icon text-white"
                            @click="command('pause')">
                            <fa-icon icon="pause" class="mr-4" />
                        </button>
                        <button v-if="showStopButton" class="button transparent button-icon text-white"
                            @click="command('stop')">
                            <fa-icon icon="stop" class="mr-4" />
                        </button>
                        <button v-if="showNextButton" class="button transparent button-icon text-white"
                            @click="command('next')">
                            <fa-icon icon="step-forward" />
                        </button>
                        <!-- <fa-icon icon="music" v-if="!isAdmin" class="text-center" /> -->
                        <img v-if="!isAdmin" src="../assets/image/logoWhite.svg" class="w-12 h-12" />
                    </div>
                </div>
                <div class="flex-1 flex flex-col p-2">
                    <p class="ml-0 mb-2 tracking-wide text-xl font-semibold" style="font-variant: small-caps">
                        {{ next ? 'Als nächstes:' : 'Kein nächster Song' }}
                    </p>
                    <div class="flex justify-center">
                        <track-card
                            v-if="next"
                            :track="next"
                            size="md"
                            :autoMarquee="true"
                            coverSize="md"
                            :titleStyle="[ 'tracking-wide', 'font-semibold', 'text-white' ]"
                        />
                    </div>
                </div>
            </template>
        </template>
        <div v-else class="flex-1 flex justify-center items-center my-2">
            <div class="navigator disconnected">
                <fa-icon icon="music" v-if="isAdmin || !hasTracks" class="text-center" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import timeFormatter from '../mixins/timeFormatter'
import TrackCard from './TrackCard.vue'
// import Volume from '../elements/Volume.vue'

export default {
    // components: { Track, Volume },
    components: { TrackCard },
    mixins: [ timeFormatter ],
    computed: {
        ...mapState({
            status: state => state.player.status,
            current: state => state.player.current,
            next: state => state.player.next,
            loading: state => state.player.loading,
            isAdmin: state => state.user.isAdmin,
            hasTracks: state => state.tracklist.tracks.length > 0
        }),
        connected () {
            return this.status !== 'disconnected'
        },
        showCurrent () {
            return Boolean(this.current)
        },
        showPlayButton () {
            return this.connected && this.isAdmin && this.status !== 'playing' && (this.hasTracks || this.current !== null || this.next !== null)
        },
        showStopButton () {
            return this.connected && this.isAdmin && this.status === 'playing'
        },
        showNextButton () {
            return this.isAdmin && (Boolean(this.next) || this.hasTracks) && this.showStopButton
        },
        showPauseButton () {
            return this.showStopButton
        }
    },
    mounted () {
        this.$store.dispatch('player/loadStatus')
        this.$store.dispatch('player/poll')
    },
    data ()     {
        return {

        }
    },
    methods: {
        command (command) {
            this.$store.dispatch('player/command', command)
        },
        focusSongSearch () {
            document.getElementById('songSearchInput').focus()
        },
        truncateString (value) {
            return value.substring(0, 70).trim() + (value.length > 37 ? '...' : '')
        }
    }
}
</script>

<style lang="sass" scoped>
@import ../assets/styles/typography.sass

.navigator
    // @apply flex-none flex w-48 h-16 flex-no-wrap items-center justify-center
    @apply flex w-48 h-16 items-center justify-center
    @apply border-2 border-gray-200 rounded-full

    &.disconnected
        @apply border-red-500 font-bold

.header
    @apply text-center font-semibold text-white tracking-wide

.no-track
    @apply text-center font-normal text-white
</style>
