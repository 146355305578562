<template>
	<div class="w-full">
		<div class="mx-auto sm:mt-20 mt-8 max-w-md bg-gray-800 rounded text-white shadow-2xl">
			<div v-if="checkingAuth" class="flex flex-col">
				<div class="flex justify-center">
					<loader loading-text="Einloggen ..." text-style="tracking-wide" />
				</div>
			</div>
			<form
				v-else-if="!loggedIn"
				@submit.prevent
				class="px-8 pt-6 pb-8"
			>
				<div class="mb-6 text-3xl font-semibold tracking-wider flex flex-no-wrap justify-center" style="font-variant: small-caps">
					<p v-if="isAdmin">
						Administrator
					</p>
					<template v-else>
						<p>Hit</p>
						<img src="../assets/image/logoWhite.svg" class="w-10 h-10 mx-4" />
						<p>Box</p>
					</template>
					<p v-if="sessionExpired" class="text-warning italic">Sitzung abgelaufen</p>
				</div>
				<div class="mb-3 relative">
					<label
						class="label-input-on-line"
						for="input-name"
					>
						{{ isAdmin ? 'Nutzername' : 'Anzeigename' }}
					</label>
					<input
						id="input-name"
						type="text"
						class="input-main w-full dark placeholder-gray-400"
                        :class="{ error: nameInvalid || nameTooLong }"
						:placeholder="(isAdmin ? 'Nutzername' : 'Anzeigename') + ' eingeben'"
						@keypress="resetNameInvalid()"
						v-model="name"
						autofocus
					/>
					<p
						class="ml-3 my-1 text-error text-sm italic"
						:class="{ invisible: !nameInvalid }"
					>
						{{ nameTooLong ? 'Maximal 30 Zeichen erlaubt' : isAdmin ? 'Benutzername unbekannt' : 'Anzeigename bereits vergeben oder verboten' }}
					</p>
				</div>
				<div
					v-show="showTokenInput || tokenInvalid"
					class="mb-4 relative"
				>
					<label
						class="label-input-on-line"
						for="input-token"
					>
						Passwort
					</label>
					<input
						id="input-token"
						:type="isAdmin ? 'password' : 'text'"
						class="input-main w-full dark placeholder-gray-400"
						:class="{ error: tokenInvalid }"
						:placeholder="(isAdmin ? 'Passwort' : 'Schlüssel') + ' eingeben'"
						v-model="token"
						@keypress="resetTokenInvalid()"
						@keyup.enter="login()"
					/>
					<p
						class="ml-3 mt-1 text-error text-sm italic"
						:class="{ invisible: !tokenInvalid }"
					>
						{{ isAdmin ? 'Passwort' : 'Schlüssel' }} ungültig
					</p>
				</div>
				<div class="relative flex items-center justify-center">
					<button
						type="button"
						class="button lime w-full"
						:disabled="loggingIn"
						@click="login()"
					>
						Anmelden
					</button>
					<loader
						v-if="loggingIn"
						class="absolute transform translate-x-20"
					/>
				</div>
				<div class="flex mt-4 text-sm justify-center">
					<button
						type="button"
						class="button-text flex underline"
						@click="toggleAdmin()"
					>
						Als {{ isAdmin ? 'Nutzer' : 'Administrator' }} anmelden
					</button>
				</div>
				<p
					v-if="$store.state.user.error !== ''"
					class="text-error text-sm font-semibold mt-2 text-center"
				>
					{{ $store.state.user.error }}
				</p>
			</form>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
		...mapState({
			checkingAuth: state => state.user.checkingAuth,
			loggedIn: state => state.user.loggedIn,
			loggingIn: state => state.user.loggingIn,
			imageExisted: state => state.user.imageExisted,
			wasLogin: state => state.user.wasLogin,
			nameFromStorage: state => state.user.nameFromStorage,
			isAdminFromStorage: state => state.user.isAdminFromStorage
		}),
		nameInvalid () {
			return this.$store.state.user.invalidFields.includes('input-name') || this.nameTooLong
		},
		nameTooLong () {
			return this.name.length > 30
		},
		tokenInvalid () {
			return this.$store.state.user.invalidFields.includes('input-token')
		},
		sessionExpired () {
			return this.$store.state.user.invalidFields.includes('session-expired')
		}
	},
	data () {
		return {
			isAdmin: false,
			name: '',
			token: '',
			showTokenInput: true
		}
	},
	watch: {
		loggedIn (newVal, oldVal) {
			if (!oldVal && newVal) {
				if(!this.wasLogin) {
					if (this.$route.query.redirect) {
						this.$router.push(this.$route.query.redirect)
					} else {
						this.$router.push('/')
					}
				} else if(!this.isAdmin) {
					if (this.imageExisted) {
						this.$router.push('confirmImage')
					} else {
						this.$router.push('welcome')
					}
				} else {
					if(!this.imageExisted) {
						this.$router.push('welcome')
					} else {
						this.$router.push('/')
					}
				}
			}
		}
	},
	mounted () {
		this.name = this.nameFromStorage
		this.isAdmin = this.isAdminFromStorage

		const token = this.$route.query.token
		if (token) {
			this.showTokenInput = false
			this.token = token
			// if user has a name from storage that is no admin, login automatically
			if (!this.isAdmin) {
				this.login()
			} else {
				this.isAdmin = false
			}
		}
	},
	methods: {
		login () {
			this.$store.dispatch('user/login',
				{ isAdmin: this.isAdmin, name: this.name, token: this.token })
		},
		toggleAdmin () {
            this.resetNameInvalid()
            this.resetTokenInvalid()
			this.token = ''
			const queryToken = this.$route.query.token
			if (this.isAdmin && queryToken) {
				this.token = queryToken
			}
			if (!this.showTokenInput) {
				this.token = ''
				this.showTokenInput = true
			}
			this.isAdmin = !this.isAdmin
		},
		resetNameInvalid () {
			if (this.nameInvalid) {
				this.$store.commit('user/resetInvalidField', 'input-name')
			}
		},
		resetTokenInvalid () {
			if (this.tokenInvalid) {
				this.$store.commit('user/resetInvalidField', 'input-token')
			}
		}
	}
}
</script>
