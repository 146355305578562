import Vue from "vue"

const baseState = () => {
    return {
        checkingAuth: false,
        loggedIn: false,
        imageExisted: false,
        sessionId: null,
        adminId: null,
        isAdmin: false,
        name: '',
        loggingIn: false,
        invalidFields: [],
        nameFromStorage: '',
        isAdminFromStorage: false,
        loadingImages: {},
        sessionImages: {},
        editingSessionId: -2
    }
}

const handleAuthenticated = async (commit, dispatch, user, axios, isLogin) => {
    commit('login', user)
    dispatch('admin/loadPublicSettings', {}, { root: true })

    const { data } = await axios.get(`session/images/${user.isAdmin ? user.sessionId : user.id}`);
    commit('setSessionImages', data);
    if (isLogin) {
        commit('wasLogin');
        if (Array.isArray(data) && data[0] && data[0].image) {
            commit('imageExisted');
        } else {
            commit('resetImageExisted');
        }
    } else {
        commit('wasNoLogin');
    }
    commit('loggedIn');
    commit('resetCheckingAuth')
    dispatch('tracklist/load', { diff: false, numDiffTracks: 0 }, { root: true })
}

const state =    baseState()

const mutations = {
    imageExisted(state) {
        state.imageExisted = true;
    },
    resetImageExisted(state) {
        state.imageExisted = false;
    },
    wasLogin(state) {
        state.wasLogin = true;
    },
    wasNoLogin(state) {
        state.wasLogin = false;
    },
    loggedIn(state) {
        state.loggedIn = true;
    },
    login (state, user) {
        state.nameFromStorage = ''
        state.isAdmin = user.isAdmin
        state.name = user.name
        state.sessionId = user.isAdmin ? user.sessionId : user.id
        state.adminId = user.id
        localStorage.setItem('sessionName', user.name)
        localStorage.setItem('isAdmin', JSON.stringify(user.isAdmin))
    },
    setUser (state, user) {
        Object.keys(user).forEach(key => {
            if (state[key]) state[key] = user[key]
        })
    },
    resetUser (state) {
        state.isAdmin = false
        state.name = ''
    },
    setCheckingAuth (state) {
        state.checkingAuth = true
    },
    resetCheckingAuth (state) {
        state.checkingAuth = false
    },
    loggingIn (state) {
        state.loggingIn = true
    },
    loggingInFinished (state) {
        state.loggingIn = false
    },
    logout (state) {
        state.loggedIn = false,
        state.isAdmin = false,
        state.name = ''
    },
    setInvalidFields (state, value) {
        state.invalidFields = value
    },
    resetInvalidField (state, field) {
        const index = state.invalidFields.indexOf(field)
        if (index > -1) {
            state.invalidFields.splice(index, 1)
        }
    },
    resetInvalidFields (state) {
        state.invalidFields = []
    },
    setFromStorage (state, value) {
        state.nameFromStorage = value.name
        state.isAdminFromStorage = value.isAdmin
    },
    setLoadingImages (state, ids) {
        if (!Array.isArray(ids)) {
            ids = [ids]
        }

        for(const id of ids) {
            state.loadingImages[id] = true;
        }
    },
    resetLoadingImages (state) {
        state.loadingImages = {}
    },
    setSessionImages (state, images) {
        for(const image of images) {
            Vue.set(state.sessionImages, image.sessionId, image.image);
            delete state.loadingImages[image.sessionId]
        }
    },
    setEditingSessionId (state, value) {
        state.editingSessionId = value
    },
    resetEditingSessionId (state) {
        state.editingSessionId = -2
    }
}

const actions = {
    async checkAuthStatus ({ commit, dispatch }) {
        commit('setCheckingAuth')
        commit('loggingIn')
        const name = localStorage.getItem('sessionName')
        let isAdmin = localStorage.getItem('isAdmin')

        if (name) {
            if (isAdmin === 'true' || isAdmin === 'false') {
                isAdmin = JSON.parse(isAdmin)
            } else {
                isAdmin = false
            }
            commit('setFromStorage', { name, isAdmin })
        }

        const { data } = await this._vm.axios.get('session/authenticated')

        if (!data.reasons) {
            handleAuthenticated(commit, dispatch, data, this._vm.axios, false)
        } else {
            commit('logout') // make sure no loggedIn state is set
            commit('resetCheckingAuth')
        }
        commit('loggingInFinished')
    },
    async login ({ commit, dispatch }, { isAdmin, name, token }) {
        commit('resetInvalidFields')
        commit('loggingIn')

        const { data } = await this._vm.axios.post('session/authenticate',
            { isAdmin, name, token })

        if (!data.reasons) {
            handleAuthenticated(commit, dispatch, data, this._vm.axios, true)
        } else {
            dispatch('handleFailedLogin', data.reasons)
        }

        commit('loggingInFinished')
    },
    async logout ({ commit, dispatch }) {
        try {
            const result = await this._vm.axios.get('session/logout')

            if (result.status === 200) {
                dispatch('player/stopPoll', {}, { root: true })
                commit('logout')
            }
        } catch (error) {
            console.log('error: ', error)
        }
    },
    handleFailedLogin ({ commit }, reasons) {
        commit('resetInvalidFields')
        if (reasons.includes('name')) {
            commit('setInvalidFields', ['input-name'])
        }
        if (reasons.includes('token')) {
            commit('setInvalidFields', ['input-token'])
        }
        if (reasons.includes('isAdmin')) {
            commit('setUser', { isAdmin: true })
        }
        if (reasons.includes('expired')) {
            commit('setInvalidFields', ['session-expired'])
        }
        else {
            console.log('Unhandled reason(s): ', reasons)
        }
    },

    async loadSessionImages ({ state, commit }, payload) {
        let sessionIds = [];
        let forceReload = false;

        if(Array.isArray(payload)) {
            sessionIds = payload;
        } else {
            sessionIds = payload.sessionIds;
            if(payload.forceReload) {
                forceReload = payload.forceReload;
            }
        }

        let idsNotPresent = [];

        if (!forceReload) {
            for(const id of sessionIds) {
                if (state.sessionImages[id] || state.loadingImages[id]) { 
                    continue;
                }
                idsNotPresent.push(id);
            }
        } else {
            idsNotPresent = sessionIds;
        }

        if(idsNotPresent.length === 0) {
            return;
        }

        commit('setLoadingImages', idsNotPresent)
        const { data } = await this._vm.axios.get(`session/images/${idsNotPresent.join(',')}`);

        if (data.reasons) {
            console.log("Unhandled reason(s): ", data.reasons);
            return;
        }
        commit('setSessionImages', data);
    },
    async rename ({ state, commit }, newName) {
        const { data } =    state.isAdmin ?
            await this._vm.axios.put('admin', { id: state.adminId, name: newName }) :
            await this._vm.axios.put('session', { id: state.sessionId, name: newName })

        if (!data.reasons) {
            commit('setUser', { name: newName })
            localStorage.setItem('sessionName', newName)
        } else {
            console.log('Rename hat nicht geklappt!')
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}