let debounce = null
let voteAnimation = null
let pollId = null

const baseState = () => {
    return {
        tracks: [],
        diff: [],
        diffCache: {},
        submittedIds: [],
        // time until track can be replayed
        cooldownIds: [],
        // trackIds the user cannot vote for again yet
        revoteTooFastIds: [],
        localVotes: [],
        recentVotes: []
    }
}

const state = baseState()

const mutations = {
    setTracks (state, tracks) {
        state.tracks = tracks
    },
    resetTracks (state) {
        state.tracks = []
    },
    setDiff (state, diff) {
        state.diff = diff
    },
    resetDiff (state) {
        state.diff = []
    },
    addDiffKey (state, { key, votes, position }) {
        state.diffCache[key] = {
            votes,
            position
        }
    },
    removeDiffKey(state, key) {
        delete state.diffCache[key]
    },
    setCooldownIds (state, value) {
        state.cooldownIds = value
    },
    resetCooldownIds (state) {
        state.cooldownIds = []
    },
    setLockedIds (state, value) {
        state.lockedIds = value
    },
    resetLockedIds (state) {
        state.lockedIds = []
    },
    setRevoteTooFastIds (state, value) {
        state.revoteTooFastIds = value
    },
    resetRevoteTooFastIds (state) {
        state.revoteTooFastIds = []
    },
    setSubmittedIds (state, value) {
        state.submittedIds = value
    },
    resetSubmittedIds (state) {
        state.submittedIds = []
    },
    setLocalVotes (state, votes) {
        const timestamp = new Date()
        for (const vote of votes) {
            const index = state.localVotes.findIndex(lv => lv.trackId === vote.trackId)
            if (index === -1) {
                state.localVotes.push({
                    trackId: vote.trackId,
                    timestamp,
                    unsafe: vote.unsafe
                })
            }
        }
    },
    sanitizeLocalVotes (state) {
        const sanitizedVotes = state.localVotes.reduce((acc, curr) => {
            const track = state.tracks.find(track => track.id === curr.trackId)
            if (track) {
                acc.push(curr)
            }
            return acc
        }, [])
        state.localVotes = sanitizedVotes
    },
    addRecentVotes (state, trackIds) {
        state.recentVotes = state.recentVotes.concat(trackIds)
    },
    removeRecentVotes (state, trackIds) {
        state.recentVotes = state.recentVotes.filter((currTrackId) => {
            return !trackIds.includes(currTrackId)
        })
    },
    resetRecentVotes (state) {
        state.recentVotes = []
    },
    removeLocal (state, trackId) {
        state.tracks = state.tracks.filter((track) => track.id !== trackId)
    }
}

const actions = {
    async vote ({ dispatch, commit }, votes) {
        if (typeof votes === 'number') {
            votes = [votes]
        }
        const { data } = await this._vm.axios.post('tracklist/vote', votes)

        if (!data.reasons) {
            dispatch('handleVoteData', { votes, data })
            if (voteAnimation) {
                clearTimeout(voteAnimation)
            }
            voteAnimation = setTimeout(() => {
                dispatch('load', { force: true })
            }, 3000);
        
        } else if (data.reasons.length === 1 && data.reasons[0] === 'votingDisabled') {
            commit('admin/setVoteable', false, { root: true })
        }
    },
    handleVoteData ({ commit, dispatch }, { votes, data }) {
        const [ submittedIds, cooldownIds, revoteTooFastIds, localVotes ]
            = data.reduce((acc, curr, index) => {
            let unsafe = undefined
            if (curr === 'ok') acc[0].push(votes[index])
            if (curr === 'cooldown') acc[1].push(votes[index])
            if (curr === 'revoteTooFast') {
                acc[2].push(votes[index])
                unsafe = true
            }
            acc[3].push({ trackId: votes[index], unsafe })
            return acc
        }, [[], [], [], []])
        commit('setSubmittedIds', submittedIds)
        commit('setCooldownIds', cooldownIds),
        commit('setRevoteTooFastIds', revoteTooFastIds)
        commit('setLocalVotes', localVotes)
        dispatch('handleRecentVotes', submittedIds)

        if (debounce) {
            clearTimeout(debounce)
        }
        // reset after X seconds
        debounce = setTimeout(() => {
                commit('setSubmittedIds', [])
                commit('setRevoteTooFastIds', [])
            }, 6000);
    },
    handleRecentVotes({ commit }, trackIds) {
        commit('addRecentVotes', trackIds)
        setTimeout(() => {
            commit('removeRecentVotes', trackIds)
        }, 6000)
    },
    async poll({ dispatch }, { diff, isTheater }) {
        const ms = isTheater ? 1000 : 10000
        await dispatch('clearPoll')

        pollId = setInterval(() => {
            dispatch('load', { diff, numDiffTracks: 10, force: false })
        }, ms)
    },
    async clearPoll() {
        clearInterval(pollId)
        pollId = null
    },
    async load ({ state, commit }, { diff, numDiffTracks, force, more }) {
        if (diff === undefined) {
            diff = false
        }
        if (numDiffTracks === undefined) {
            numDiffTracks = 10
        }
        if (force === undefined) {
            force = true
        }
        const [offset, limit] = [0, more? state.tracks.length < 10 ? 10 : state.tracks.length + 10 : state.tracks.length < 10 ? 10 : state.tracks.length]
        if (voteAnimation) {
            clearTimeout(voteAnimation)
        }
        const forceParam = force ? '/force' : ''
        const { data } = await this._vm.axios.get(`tracklist/tracks${forceParam}/${offset}/${limit}`)
        if (!data.reasons) {
            if (diff) {
                if (numDiffTracks > data.tracklist.length) {
                    numDiffTracks = data.tracklist.length
                }
                
                const diffIndices = []
                for (let i = 0; i < numDiffTracks; i++) {
                    const trackId = data.tracklist[i].id
                    if (!(trackId in state.diffCache) || (state.diffCache[trackId].votes !== data.tracklist[i].votes.length)) {
                        diffIndices.push(i);
                        commit('addDiffKey', { key: trackId, votes: data.tracklist[i].votes.length, position: i });
                    }
                }
                commit('setDiff', diffIndices)
                for (const key of Object.keys(state.diffCache)) {
                    let found = false;
                    for (const track of data.tracklist) {
                        if (track.id == key) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        commit('removeDiffKey', key);
                    }
                }
            }
            commit('setTracks', data.tracklist)
        } 

        commit('sanitizeLocalVotes')
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}