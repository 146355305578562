<template>
    <div class="mx-auto sm:mt-20 mt-8 max-w-md text-center">
        <p class="text-3xl">Willkommen in der Hitbox!</p>
        <div class="my-4 mx-auto w-40">
            <img src="../assets/image/logoWhite.svg" />
        </div>
        <p class="mt-4">Nimm direkt ein Foto auf,<br/> damit dich alle erkennen!</p>
        <p class="mt-2">Viel Spaß beim Abstimmen!</p>
        <button
            type="button"
            class="button green mt-2 w-3/4"
            @click="$router.push({ name: 'profile', params: { openDialog: true } })"
        >
            <fa-icon icon="camera" class="mr-2" />
            Foto aufnehmen
        </button>
        <button
            type="button"
            class="button red mt-2 w-3/4"
            @click="$router.push('/')"
        >
            <fa-icon icon="times" class="mr-2" />
            Direkt zur Abstimmung
        </button>
        
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    
    computed: {
		...mapState({
			wasLogin: state => state.user.wasLogin,
		}),
    },

    mounted() {
        if(!this.wasLogin) {
            this.$router.push('/');
        }
    },

    destroyed () {
		this.$store.commit("user/wasNoLogin");
	},
}
</script>
