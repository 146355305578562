import Vue from 'vue'

let debounce = null

const baseState = () => ({
  sessions: [],
  admins: [],
  forbiddenNames: [],
  voteable: true,
  replayCooldown: 0,
  revoteCooldown: 0,
  pollslaveToken: '',
  sessionToken: '',
  reasons: []
})

const state = baseState()

const getters = {
  settings: state => ({
    forbiddenNames: state.forbiddenNames,
    voteable: state.voteable,
    replayCooldown: state.replayCooldown,
    revoteCooldown: state.revoteCooldown,
    pollslaveToken: state.pollslaveToken,
    sessionToken: state.sessionToken
  })
}

const mutations = {
  setSessions (state, value) {
    state.sessions = []
    for(const sess of value) {
      sess.sessionId = sess.id;
      delete sess.id;
      state.sessions.push(sess);
    }
  },
  resetSessions (state) {
    state.sessions = []
  },
  setAdmins (state, value) {
    state.admins = value
  },
  resetAdmins (state) {
    state.admins = []
  },
  setForbiddenNames (state, value) {
    state.forbiddenNames = value
  },
  addForbiddenName (state, value) {
    state.forbiddenNames.push(value)
  },
  removeForbiddenName (state, value) {
    const index = state.forbiddenNames.findIndex(name => name === value)
    if (index > -1) {
      state.forbiddenNames.splice(index, 1)
    } else console.log('something went wrong removing forbidden name ', value)
  },
  resetForbiddenNames (state) {
    state.forbiddenNames = []
  },
  setSettings (state, settings) {
    for (const key of Object.keys(settings)) {
      state[key] = settings[key]
    }
  },
  resetSettings (state) {
    state.session.settings = []
  },
  setVoteable (state, value) {
    state.voteable = value
  },
  setReplayCooldown (state, value) {
    state.replayCooldown = value
  },
  setRevoteCooldown (state, value) {
    state.revoteCooldown = value
  },
  setPollslaveToken (state, value) {
    state.pollslaveToken = value
  },
  setSessionToken (state, value) {
    state.sessionToken = value
  },
  setSettingsSaved (state, value) {
    state.settingsSaved = value
  },
  setReasons (state, reasons) {
    state.reasons = reasons
  },
  resetReasons (state) {
    state.reasons = []
  }
}

const actions = {
  async loadSessions ({ commit }) {
    const sessionResult = await Vue.axios.get('session/all')
    const adminResult = await Vue.axios.get('admin')

    return new Promise((resolve) => {
      const [ sessions, admins ] = [ sessionResult.data, adminResult.data ]

      if (!admins.reasons) {
        commit('setAdmins', admins)
        commit('setSessions', sessions)
      } else {
        Vue.notify('Nutzer konnten nicht geladen werden', {
          type: 'error'
        })
      }
      resolve()
    })
  },
  async loadSettings ({ commit }) {
    const { data } = await Vue.axios.get('settings')

    return new Promise((resolve) => {

      if (!data.reasons) {
        commit('setSettings', data)
      } else {
        Vue.notify('Einstellungen konnten nicht geladen werden', {
          type: 'error'
        })
      }
      resolve()
    })
  },
  async saveSettings ({ getters, commit }) {
    const { data } = await Vue.axios.put('settings', getters.settings)

    if (!data.reasons) {
      Vue.notify('Einstellungen gespeichert', {
        type: 'success'
      })
      commit('setSettingsSaved', 'success')
    } else {
      Vue.notify('Einstellungen speichern fehlgeschlagen', {
        type: 'error'
      })
      console.log('da ist was schief gelaufen: ', data.reasons)
      commit('setSettingsSaved', 'error')
    }
  },
  async loadPublicSettings ({ commit }) {
    const { data } = await Vue.axios.get('settings/public')

    if (!data.reasons) {
      commit('setSettings', data)
    } else {
      console.log('da ist was schief gelaufen: ', data.reasons)
    }
  },
  async saveSession ({ dispatch }, session) {
    const { data } = await Vue.axios.put('session', session)

    if (!data.reasons) {
      Vue.notify('Änderungen gespeichert', {
        type: 'success'
      })
      dispatch('loadSessions')
    } else {
      Vue.notify('Speichern fehlgeschlagen', {
        type: 'error'
      })
      console.log('da ist was schief gelaufen: ', data.reasons)
    }
  },
  async saveAdmin ({ dispatch }, admin) {
    const { data } = await Vue.axios.put('admin', admin)

    if (!data.reasons) {
      Vue.notify('Änderungen gespeichert', {
        type: 'success'
      })
      dispatch('loadSessions')
    } else {
      Vue.notify('Änderungen fehlgeschlagen', {
        type: 'error'
      })
      console.log('da ist was schief gelaufen: ', data.reasons)
    }
  },
  async createAdmin ({ dispatch }, newAdmin) {
    const { data } = await Vue.axios.post('admin', newAdmin)

    if (!data.reasons) {
      Vue.notify('Admin erstellt', {
        type: 'success'
      })
      dispatch('loadSessions')
    } else {
      Vue.notify('Admin erstellen fehlgeschlagen', {
        type: 'error'
      })
      console.log('da ist was schief gelaufen: ', data.reasons)
    }
  },
  setReasons ({ commit }, reasons) {
    commit('setReasons', reasons)

    if (debounce) clearTimeout(debounce)

    debounce = setTimeout(() => {
      commit('resetReasons')
    }, 8000)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}