export default {
    methods: {
        voteStatus (trackId, submittedIds, revoteTooFastIds) {
            let status = 'neutral'
            if (!trackId || !Number.isInteger(trackId)) {
                throw 'trackId is not Integer'
            }
            if (!submittedIds || !Array.isArray(submittedIds)) {
                throw 'submittedIds is no Array'
            }
            if (!revoteTooFastIds || !Array.isArray(revoteTooFastIds)) {
                throw 'revoteTooFastIds is no Array'
            }

            if (submittedIds.includes(trackId)) {
                return status = 'success'
            }
            else if (revoteTooFastIds.includes(trackId)) {
                status = 'revoteTooFast'
            }
            return status
        }
    }
}