<template>
  <div ref="nofiyContainer" id="notifyContainer">
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
#notifyContainer
  @apply fixed z-20 right-0 transform translate-y-10 -translate-x-10 flex flex-col
</style>
