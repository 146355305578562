<template>
  <div
    class="flex flex-col bg-gray-800 my-2 p-2 shadow-lg relative text-center"
    :class="{'border-2 border-lime-400': name === currentUserName }"
  >
    <div class="mx-auto w-24 h-24 rounded-full overflow-hidden bg-gray-700 border-2 border-white flex items-center justify-center">
      <img v-if="profileImages[id]" :src="profileImages[id]" alt="Bild nicht geladen"/>
      <fa-icon v-else icon="user-tie" size="3x" />
    </div>
    <p class="my-1 font-semibold text-xl">{{ name }}</p>
    <div class="text-gray-400">
      <template v-if="lastSeen">
        <p class="whitespace-no-wrap">
          Zuletzt aktiv vor
          {{ toLocalTime(lastSeen, { relative: true }) }}
        </p>
      </template>
      <p v-else class="whitespace-no-wrap italic">
        Keine Aktivität
      </p>
    </div>
    <div class="absolute top-0 right-0 pt-2 pr-2">
      <button
        class="button transparent button-icon"
        @click="toggleEdit()"
      >
        <fa-icon :icon="editingId === id ? 'times' : 'edit'" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import timeFormatter from '../mixins/timeFormatter'

export default {
  mixins: [
    timeFormatter
  ],
  props: {
    id: {
      type: Number
    },
    name: {
      type: String
    },
    lastSeen: {
      type: String
    }
  },
  computed: {
    ...mapState({
      profileImages: state => state.user.sessionImages,
      currentUserName: state => state.user.name,
      editingId: state => state.user.editingSessionId
    })
  },
  data () {
    return {
      newName: ''
    }
  },
  methods: {
    toggleEdit () {
      if (this.editingId === this.id) {
        this.$store.commit('user/resetEditingSessionId')
      } else {
        this.$store.commit('user/setEditingSessionId', this.id)
      }
    }
  }
}
</script>

<style lang="sass" scoped>

</style>