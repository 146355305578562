import Container from './Container.vue'
import Notification from './Notification.vue'

const notifications = []

const expireNotification = (id, timeout) => {
  setTimeout(() => {
    removeNotify(id)
  }, timeout)
}

const removeNotify = (id) => {
  const index = notifications.findIndex(x => x.$el.id === id)
  if (index > -1) {
    notifications[index].$destroy()
    document.getElementById(id).remove()
    notifications.splice(index, 1)
  }
}

export default {
  install (Vue) {
    Vue.component('notification', Container)

    Vue.notify = Vue.prototype.$notify = (message, settings = {}) => {
      const ComponentClass = Vue.extend(Notification)
      const instance = new ComponentClass({
        propsData: { message, ...settings }
      })
      instance.$mount()
      const instanceId = Math.random().toString(36).substr(2, 5);
      instance.$el.setAttribute('id', instanceId)
      document.getElementById('notifyContainer').appendChild(instance.$el)
      notifications.push(instance)
      expireNotification(instanceId, settings.timeout || 5000)
    }

    Vue.notifyClear = Vue.prototype.$notifyClear = (id) => {
      if (!id) return
      removeNotify(id)
    }
  }
}