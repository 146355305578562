import AddToPlaylist from './views/AddToPlaylist.vue'
import Accounts from './views/Accounts.vue'
import ConfirmImage from './views/ConfirmImage.vue'
import LockArtist from './views/LockArtist.vue'
import LockedMusic from './views/LockedMusic.vue'
import Login from './views/Login.vue'
import Playlists from './views/Playlists.vue'
import Profile from './views/Profile.vue'
import Qr from './views/Qr.vue'
import SearchResult from './views/SearchResult.vue'
import Settings from './views/Settings.vue'
import Stats from './views/Stats.vue'
import Tracklist from './views/Tracklist.vue'
import Theater from './views/Theater.vue'
import Welcome from './views/Welcome.vue'

export default {
  mode: 'history',
  routes: [
    {
      path: '*',
      name: 'Tracklist',
      component: Tracklist,
      meta: {
        requiresAuth: true,
        title: 'Abstimmung'
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        requiresAuth: true,
        title: 'Einstellungen'
      }
    },
    {
      path: '/playlists',
      name: 'playlists',
      component: Playlists,
      meta: {
        requiresAuth: true,
        title: 'Playlists'
      }
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: Accounts,
      meta: {
        requiresAuth: true,
        title: 'Accounts'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false,
        title: 'Login'
      }
    },
    {
      path: '/confirmImage',
      name: 'confirmImage',
      component: ConfirmImage,
      meta: {
        requiresAuth: true,
        title: 'Bild bestätigen'
      }
    },
    {
      path: '/search',
      name: 'search',
      component: SearchResult,
      meta: {
        requiresAuth: true,
        title: 'Suchergebnisse'
      }
    },
    {
      path: '/lockedMusic',
      name: 'lockedMusic',
      component: LockedMusic,
      meta: {
        requiresAuth: true,
        title: 'Gesprerrte Musik'
      }
    },
    {
      path: '/stats',
      name: 'stats',
      component: Stats,
      meta: {
        requiresAuth: true,
        title: 'Statistiken'
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true,
        title: 'Profil'
      }
    },
    {
      path: '/addToPlaylist/:trackId',
      name: 'addToPlaylist',
      component: AddToPlaylist,
      meta: {
        requiresAuth: true,
        title: 'Zu Playlist hinzufügen'
      }
    },
    {
      path: '/qr',
      name: 'qr',
      component: Qr,
      meta: {
        requiresAuth: true,
        title: 'QR-Code scannen'
      }
    },
    {
      path: '/theater',
      name: 'theater',
      component: Theater,
      meta: {
        requiresAuth: true,
        title: 'Theatermodus'
      }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      meta: {
        requiresAuth: true,
        title: 'Wilkommen'
      }
    },
    {
      path: '/lockArtist',
      name: 'lockArtist',
      component: LockArtist,
      meta: {
        requiresAuth: true,
        title: 'Künstler sperren'
      }
    }
  ]
}