<template>
    <div class="flex justify-center">
        <div class="w-full max-w-2xl">
            <div class="horizontal-separator">
                <span /><p>Authentifizierung</p><span />
            </div>
            <!-- session token -->
            <div class="setting-item">
                <div class="setting-key">
                    Sitzungsschlüssel
                </div>
                <div class="setting-value">
                    <div
                        v-if="editingSessionToken"
                        class="relative sm:w-64 w-32"
                    >
                        <input
                            id="sessionTokenInput"
                            class="input-main w-full"
                            v-model="newSessionToken"
                            type="text"
                            @keyup.enter="save('sessionToken')"
                            @keyup.esc="resetNewSessionToken()"
                        />
                        <div class="input-actions">
                            <button
                                class="button transparent button-icon"
                                @click="resetNewSessionToken()"
                            >
                                <fa-icon icon="times" />
                            </button>
                        </div>
                    </div>
                    <template v-else>
                        <p v-if="sessionToken">
                            {{ sessionToken }}
                        </p>
                        <p v-else class="text-gray-700">
                            - nicht gesetzt -
                        </p>
                    </template>
                </div>
                <div class="setting-actions">
                    <button
                        v-if="!editingSessionToken"
                        class="button transparent button-icon ml-2"
                        @click="setSessionTokenInput()"
                    >
                        <fa-icon icon="edit" />
                    </button>
                    <button
                        v-else
                        class="button transparent button-icon mx-2"
                        @click="save('sessionToken')"
                    >
                        <fa-icon icon="save" />
                    </button>
                </div>
            </div>
            <!-- pollslave token -->
            <div class="setting-item">
                <div class="setting-key">
                    Pollslaveschlüssel
                </div>
                <div class="setting-value">
                    <div
                        v-if="editingPollslaveToken"
                        class="relative sm:w-64 w-32"
                    >
                        <input
                            id="pollslaveTokenInput"
                            class="input-main w-full"
                            v-model="newPollslaveToken"
                            type="text"
                            @keyup.enter="save('pollslaveToken')"
                            @keyup.esc="resetNewPollslaveToken()"
                        />
                        <div class="input-actions">
                            <button
                                class="button transparent button-icon"
                                @click="resetNewPollslaveToken()"
                            >
                                <fa-icon icon="times" />
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <p v-if="pollslaveToken">
                            {{ pollslaveToken }}
                        </p>
                        <p v-else class="text-gray-700">
                            - nicht gesetzt -
                        </p>
                    </div>
                </div>
                <div class="setting-actions">
                    <button
                        v-if="!editingPollslaveToken"
                        class="button transparent button-icon"
                        @click="setPollslaveTokenInput()"
                    >
                        <fa-icon icon="edit" />
                    </button>
                    <button
                        v-else
                        class="button transparent button-icon"
                        @click="save('pollslaveToken')"
                    >
                        <fa-icon icon="save" />
                    </button>
                </div>
            </div>
            <div class="horizontal-separator mt-2">
                <span /><p>Abstimmung</p><span />
            </div>
            <!-- poll activision/deactivision -->
            <div class="setting-item">
                <div class="setting-key">
                    Abstimmung
                </div>
                <div class="setting-value">
                    <dropdown
                        v-if="editingVoteable"
                        size="sm"
                        :preselect="selectedVoteableOption"
                        :options="voteableOptions"
                        @change="(option) => setVoteable(option)"
                    />
                    <div v-else>
                        {{ voteable ? 'aktiv' : 'ausgeschaltet' }}
                    </div>
                </div>
                <div class="setting-actions">
                    <button
                        v-if="!editingVoteable"
                        class="button transparent button-icon"
                        @click="editingVoteable = true"
                    >
                        <fa-icon icon="edit" />
                    </button>
                    <button
                        v-else
                        class="button transparent button-icon"
                        @click="save('voteable')"
                    >
                        <fa-icon icon="save" />
                    </button>
                </div>
            </div>
            <!-- replay cooldown -->
            <div class="setting-item">
                <div class="setting-key">
                    Replay
                </div>
                <div class="setting-value">
                    <div
                        v-if="editingReplayCooldown"
                        class="relative sm:w-64 w-32"
                    >
                        <input
                            id="replayCooldownInput"
                            class="input-main w-full"
                            v-model.number="newReplayCooldown"
                            type="text"
                            pattern="[0-9]*"
                            @keyup.enter="save('replayCooldown')"
                            @keyup.esc="resetNewReplayCooldown()"
                        />
                        <div class="input-actions">
                            <button
                                class="button transparent button-icon"
                                @click="resetNewReplayCooldown()"
                            >
                                <fa-icon icon="times" />
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        {{ replayCooldown }} Minuten
                    </div>
                </div>
                <div class="setting-actions">
                    <button
                        v-if="!editingReplayCooldown"
                        class="button transparent button-icon"
                        @click="setReplayInput()"
                    >
                        <fa-icon icon="edit" />
                    </button>
                    <button
                        v-else
                        class="button transparent button-icon"
                        @click="save('replayCooldown')"
                    >
                        <fa-icon icon="save" />
                    </button>
                </div>
            </div>
            <!-- revote cooldown -->
            <div class="setting-item">
                <div class="setting-key">
                    Revote
                </div>
                <div class="setting-value">
                    <div
                        v-if="editingRevoteCooldown"
                        class="relative sm:w-64 w-32"
                    >
                        <input
                            id="revoteCooldownInput"
                            class="input-main w-full"
                            v-model.number="newRevoteCooldown"
                            type="text"
                            pattern="[0-9]*"
                            @keyup.enter="save('revoteCooldown')"
                            @keyup.esc="resetNewRevoteCooldown()"
                        />
                        <div class="input-actions">
                            <button
                                class="button transparent button-icon"
                                @click="resetNewRevoteCooldown()"
                            >
                                <fa-icon icon="times" />
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        {{ revoteCooldown }} Minuten
                    </div>
                </div>
                <div class="setting-actions">
                    <button
                        v-if="!editingRevoteCooldown"
                        class="button transparent button-icon"
                        @click="setRevoteInput()"
                    >
                        <fa-icon icon="edit" />
                    </button>
                    <button
                        v-else
                        class="button transparent button-icon"
                        @click="save('revoteCooldown')"
                    >
                        <fa-icon icon="save" />
                    </button>
                </div>
            </div>
            <!-- reset tracklist -->
            <div class="setting-item">
                <div class="setting-key">
                    Tracklist
                </div>
                <div class="setting-actions">
                    <button
                        v-if="!editingRevoteCooldown"
                        class="button red"
                        :disabled="removingTracks"
                        @click="resetTracklist()"
                    >
                        <loader v-if="removingTracks" class="mr-2" />
                        <fa-icon v-else icon="trash" class="mr-2" />
                        Alle Stimmen löschen
                    </button>
                </div>
            </div>
            <div class="horizontal-separator mt-2">
                <span /><p>Verbotene Namen</p><span />
            </div>
            <!-- forbidden names -->
            <div class="flex flex-col mt-2">
                <div v-if="addingForbiddenName"
                    class="flex items-center justify-center"
                >
                    <div class="relative sm:w-64 w-32">
                        <input
                            id="forbiddenNameInput"
                            class="input-main w-full"
                            placeholder="Namen eingeben"
                            type="text" v-model="newForbiddenName"
                            @keyup.enter="addForbiddenName()"
                            @keyup.esc="newForbiddenName = ''"
                        />
                        <div class="input-actions">
                            <button
                                class="button transparent button-icon"
                                @click="addingForbiddenName = false"
                            >
                                <fa-icon icon="times" />
                            </button>
                        </div>
                    </div>
                    <button
                        class="button button-icon not-round ml-2"
                        title="Name hinzufügen"
                        @click="addForbiddenName()"
                    >
                        Hinzufügen
                        <fa-icon icon="plus" class="ml-2" />
                    </button>
                </div>
                <p v-if="forbiddenNames.length === 0"
                    class="text-center">Keine verbotenen Nutzernamen</p>
                <button
                    v-if="!addingForbiddenName"
                    class="button mx-2"
                    @click="setForbiddenNameInput()"
                >
                    <p class="ml-2">Name hinzufügen</p>
                </button>
                <div class="flex flex-wrap justify-center">
                    <div
                        v-for="(forbiddenName, i) in forbiddenNames"
                        :key='`forbidden-name-${i}`'
                        class="forbidden-name-chip"
                        :title="forbiddenName"
                    >
                        <p class="truncate max-w-2xs">{{ forbiddenName }}</p>
                        <button
                            class="button transparent button-icon ml-2"
                            @click.stop="removeForbiddenName(forbiddenName)"
                        >
                            <fa-icon icon="trash-alt" />
                        </button>
                        <div
                            v-if="forbiddenName.length > 21"
                            class="absolute top-0 left-0 transform -translate-y-2"
                        >
                            <button
                                class="button gray button-icon italic w-6 h-6 font-normal"
                                @click="showFullForbiddenName(forbiddenName)"
                            >
                                i
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="showingFullForbiddenName !== ''"
            class="fixed bottom-0 max-w-xs bg-gray-200 py-2 px-3 shadow-xl rounded-t-sm"
        >
            <p class="mt-2 text-center">{{ showingFullForbiddenName }}</p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import timeFormatter from '../mixins/timeFormatter'
import Dropdown from '../components/Dropdown.vue'
import Loader from '../elements/Loader.vue'

export default {
    components: {
        Dropdown,
        Loader
    },
    mixins: [
        timeFormatter
    ],
    computed: {
        ...mapState({
            forbiddenNames: state => state.admin.forbiddenNames,
            voteable: state => state.admin.voteable,
            replayCooldown: state => state.admin.replayCooldown,
            revoteCooldown: state => state.admin.revoteCooldown,
            pollslaveToken: state => state.admin.pollslaveToken,
            sessionToken: state => state.admin.sessionToken
        }),
        selectedVoteableOption () {
            return this.voteableOptions.find(option => option.value === this.voteable)
        }
    },
    data () {
        return {
            delayedSafe: null,
            addingForbiddenName: false,
            newForbiddenName: '',
            editingReplayCooldown: false,
            editingRevoteCooldown: false,
            editingPollslaveToken: false,
            editingSessionToken: false,
            editingVoteable: false,
            newReplayCooldown: '',
            newRevoteCooldown: '',
            newPollslaveToken: '',
            newSessionToken: '',
            newVoteable: false,
            voteableOptions: [
                { key: 'enabled', value: true, name: 'aktiv' },
                { key: 'disabled', value: false, name: 'inaktiv' }
            ],
            showingFullForbiddenName: '',
            showingFullForbinnenNameTimeoutId: null,
            removingTracks: false
        }
    },
    beforeMount () {
        this.loadSettings()
    },
    methods: {
        async loadSettings () {
            await this.$store.dispatch('admin/loadSettings')
        },
        saveSettings () {
            this.$store.dispatch('admin/saveSettings')
        },
        async save (setting) {
            await this.loadSettings()
            switch (setting) {
                case 'voteable':
                    this.$store.commit('admin/setVoteable', this.newVoteable)
                    this.resetVoteable()
                    break
                case 'replayCooldown':
                    this.$store.commit('admin/setReplayCooldown', this.newReplayCooldown)
                    this.resetNewReplayCooldown()
                    break
                case 'revoteCooldown':
                    this.$store.commit('admin/setRevoteCooldown', this.newRevoteCooldown)
                    this.resetNewRevoteCooldown()
                    break
                case 'pollslaveToken':
                    this.$store.commit('admin/setPollslaveToken', this.newPollslaveToken)
                    this.resetNewPollslaveToken()
                    break
                case 'sessionToken':
                    this.$store.commit('admin/setSessionToken', this.newSessionToken)
                    this.resetNewSessionToken()
                    break
                default:
                    break
            }
            this.saveSettings()
        },
        addForbiddenName () {
            if (this.newForbiddenName !== '' &&
                !this.forbiddenNames.includes(this.newForbiddenName)) {
                    this.$store.commit('admin/addForbiddenName', this.newForbiddenName)
                    this.newForbiddenName = ''
                    this.saveSettings()
            }
        },
        removeForbiddenName (name) {
            this.$store.commit('admin/removeForbiddenName', name)
        },
        setPollslaveTokenInput () {
            this.editingPollslaveToken = true
            this.newPollslaveToken = this.pollslaveToken
            this.$nextTick(() => {
                document.getElementById('pollslaveTokenInput').focus()
            })
        },
        resetNewPollslaveToken () {
            this.newPollslaveToken = ''
            this.editingPollslaveToken = false
        },
        setReplayInput () {
            this.editingReplayCooldown = true
            this.newReplayCooldown = this.replayCooldown
            this.$nextTick(() => {
                document.getElementById('replayCooldownInput').focus()
            })
        },
        resetNewReplayCooldown () {
            this.newReplayCooldown = ''
            this.editingReplayCooldown = false
        },
        setRevoteInput () {
            this.editingRevoteCooldown = true
            this.newRevoteCooldown = this.revoteCooldown
            this.$nextTick(() => {
                document.getElementById('revoteCooldownInput').focus()
            })
        },
        resetNewRevoteCooldown() {
            this.newRevoteCooldown = ''
            this.editingRevoteCooldown = false
        },
        setSessionTokenInput () {
            this.editingSessionToken = true
            this.newSessionToken = this.sessionToken
            this.$nextTick(() => {
                document.getElementById('sessionTokenInput').focus()
            })
        },
        resetNewSessionToken () {
            this.newSessionToken = ''
            this.editingSessionToken = false
        },
        setVoteable (option) {
            this.editingVoteable = true
            this.newVoteable = option.value
        },
        resetVoteable () {
            this.newVoteable = false
            this.editingVoteable = false
        },
        setForbiddenNameInput () {
            this.addingForbiddenName = true
            this.$nextTick(() => {
                document.getElementById('forbiddenNameInput').focus()
            })
        },
        showFullForbiddenName (name) {
            this.showingFullForbiddenName = name

            if (this.showingFullForbinnenNameTimeoutId) clearTimeout(this.showingFullForbinnenNameTimeoutId)

            this.showingFullForbinnenNameTimeoutId = setTimeout(() => {
                this.showingFullForbiddenName = ''
            }, 5000)
        },
        async resetTracklist () {
            this.removingTracks = true
            const { data } = await this.axios.put('tracklist/clear')
            if (!data.reasons) {
                this.$store.commit('tracklist/resetTracks')
            }
            this.removingTracks = false
        }
    }
}
</script>

<style lang="sass" scoped>
.setting-item
    @apply flex items-center p-2 mt-2 border-b-2 border-gray-400

    .setting-key
        @apply flex-none font-semibold w-40

    .setting-value
        @apply mx-2 flex-grow

    .setting-actions
        @apply flex-none

.forbidden-name-chip
    @apply relative flex inline-flex items-center rounded-full bg-indigo-900 pl-5 pr-2 py-2 m-2 max-w-2xs

    &:hover
        @apply bg-blue-400

    &.interactive
        @apply bg-green-500 cursor-pointer text-white font-semibold

        &:hover
            @apply bg-green-700
</style>
