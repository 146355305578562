<template>
  <div class="flex flex-no-wrap items-center justify-center" :class="textColor">
    <svg class="animate-spin h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"/>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
    </svg>
    <p v-if="loadingText !== ''" class="font-semibold" :class="textStyle">{{ loadingText }}</p>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'dark',
      validator (value) {
        return ['dark', 'light'].includes(value)
      }
    },
    loadingText: {
      type: String,
      default: ''
    },
    textStyle: {
      type: String,
      default: ''
    }
  },
  computed: {
    textColor () {
      if (this.theme === 'light') {
        return 'text-black'
      } else {
        return 'text-white'
      }
    }
  }
}
</script>