<template>
  <div
    class="notification"
    :class="type"
  >
    <span v-if="icon" class="icon">
      <fa-icon :icon="icon" />
    </span>
    <div class="content">
      <p v-if="title" class="font-semibold">{{ title }}</p>
      <p class="text-sm whitespace-normal">{{ message }}</p>
    </div>
    <div v-if="actions.length" class="actions">
      <button class="button transparent button-icon">
        <fa-icon icon="times" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      validator (value) {
        return ['default', 'success', 'warning', 'error'].includes(value)
      },
      default: 'default'
    },
    actions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  beforeDestory () {
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="sass" scoped>
.notification
  @apply flex flex-no-wrap items-center py-2 px-4 mb-2 w-56 overflow-hidden
  @apply border-t-4 bg-gray-300 border-gray-700 text-gray-900 shadow-lg

  .icon
    @apply mr-4

  .content
    @apply flex flex-col

  .actions
    @apply flex inline-flex items-center ml-4

  &.success
    @apply bg-teal-200 border-green-700

  &.warning
    @apply bg-yellow-200 border-yellow-700

  &.error
    @apply bg-red-200 border-red-700
</style>
