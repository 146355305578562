export default {
    bind (el, binding, vNode) {
        // console.log('el: ', el)
        // console.log('binding: ', binding)
        // console.log('vNode', vNode)

        if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) { warn += `Found in component '${compName}' ` }
            console.warn(warn)
        }

        let pressTimer = null

        const start = (e) => {
            if (e.type === 'click' && e.button !== 0) {
                return
            }

            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    handler(e, 'test')
                }, 1000)
            }
        }

        // Cancel Timeout
        let cancel = (e) => { // eslint-disable-line no-unused-vars
            // Check if timer has a value or not
            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }
        // Run Function
        const handler = (e) => {
            binding.value(e)
        }

        // Add Event listeners
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);
        // Cancel timeouts if this events happen
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
    }
}