<template>
    <div class="flex flex-col">
        <div class="flex justify-around">
            <!-- <div class="flex items-center justify-center">
                <span v-if="trackCount !== null" class="stat-chip mt-2">
                    <p class="key">Gesuchte Songs</p>
                    <p class="value">{{ trackCount }}</p>
                </span>
            </div> -->
            <div class="flex items-center justify-center">
                <span v-if="tracksPlayed !== null" class="stat-chip mt-2">
                    <p class="key">Gespielte Songs</p>
                    <p class="value">{{ tracksPlayed }}</p>
                </span>
            </div>
            <div v-if="activeTab !== 'history'" class="flex items-center justify-center">
                <p class="font-semibold mt-2">Zeitraum</p>
                <div class="select mt-2 ml-2">
                    <select
                        v-model="since"
                        @change="reload"
                    >
                        <option :value="1000 * 60 * 60 * 24 * 1">1 Tag</option>
                        <option :value="1000 * 60 * 60 * 24 * 3">3 Tage</option>
                        <option :value="1000 * 60 * 60 * 24 * 7">1 Woche</option>
                        <option :value="0">Gesamter Zeitraum</option>
                    </select>
                    <div>
                        <fa-icon icon="chevron-down" />
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-menu mt-2">
            <div class="tab-header-item"
                :class="{ active: activeTab === 'history' }"
                @click="setActiveTab('history')">
                Letzte Songs
            </div>
            <div class="tab-header-item"
                :class="{ active: activeTab === 'artists' }"
                @click="setActiveTab('artists')">
                Beliebteste Künstler
            </div>
            <div class="tab-header-item"
                :class="{ active: activeTab === 'tracks' }"
                @click="setActiveTab('tracks')">
                Meistgespielte Songs
            </div>
        </div>
        <track-history
            v-if="activeTab === 'history'"
            :history="history"
            @load-more="loadMoreHistory"
        />
        <artist-popularity
            v-if="activeTab === 'artists'"
            :artists="artists"
            @load-more="loadMoreArtists"
        />
        <track-popularity
            v-if="activeTab === 'tracks'"
            :tracks="tracks"
            @load-more="loadMoreTracks"
        />
    </div>
</template>

<script>
import TrackHistory from '../components/TrackHistory.vue'
import ArtistPopularity from '../components/ArtistPopularity.vue'
import TrackPopularity from '../components/TrackPopularity.vue'

export default {
    components: {
        TrackHistory, ArtistPopularity, TrackPopularity
    },
    data ()     {
        return {
            activeTab: 'history',
            tracks: [],
            artists: [],
            trackCount: 0,
            tracksPlayed: 0,
            history: [],
            selectSince: false,
            since: 1000 * 60 * 60 * 24 * 3
        }
    },
    beforeMount () {
        this.loadTrackCountSearched()
        this.loadTrackCountPlayed()
        this.loadHistory()
    },
    methods: {
        reload () {
            this.loadTrackCountSearched()
            this.loadTrackCountPlayed()

            if (this.activeTab === 'history') {
                this.history = []
                this.loadHistory()
            } else if (this.activeTab === 'artists') {
                this.loadMostPlayedArtists()
            } else if (this.activeTab === 'tracks') {
                this.loadMostPlayedTracks()
            }
        },
        setActiveTab (tab) {
            this.activeTab = tab
            if (this.activeTab === 'history') {
                this.loadHistory()
                this.loadTrackCountPlayed()
            } else if (this.activeTab === 'artists') {
                this.loadMostPlayedArtists()
                this.loadTrackCountPlayed()
            } else if (this.activeTab === 'tracks') {
                this.loadMostPlayedTracks()
                this.loadTrackCountPlayed()
            }
        },
        async loadTrackCountSearched () {
            const { data } = await this.axios.get('stats/trackCountSearched')
            if (!data.reasons) {
                this.trackCount = data
            } else {
                this.$notify('Fehler beim Laden der Stats: ', data.reasons)
            }
        },
        async loadTrackCountPlayed () {
            const { data } = await this.axios.get(`stats/trackCountPlayed/${this.getSince()}`)
            if (!data.reasons) {
                this.tracksPlayed = data
            } else {
                this.$notify('Fehler beim Laden der Stats: ', data.reasons)
            }
        },
        async loadHistory (offset = 0, limit = 10) {
            if (limit < 10 || offset < 0 || offset < this.history.length) return
            const { data } = await this.axios.get(`stats/history/${offset}/${limit}`)

            if (!data.reasons) {
                const newHistories = data || []
                this.history = this.history.concat(newHistories)
            } else this.$notify('Fehler beim Laden der Stat-Historie: ', data.reasons)
        },
        async loadMostPlayedArtists (offset = 0, limit = 10) {
            if (offset < 0 || limit < 10) return

            const since = this.getSince()
            const { data } = await this.axios.get(`stats/mostPlayedArtists/${since}/${offset}/${limit}`)
            if (!data.reasons) {
                const newArtists = data || []
                this.artists = offset === 0 ? newArtists : this.artists.concat(newArtists)
            } else {
                this.$notify('Fehler beim Laden der Stats: ', data.reasons)
            }
        },
        async loadMostPlayedTracks (offset = 0, limit = 10) {
            if (offset < 0 || limit < 10) return

            const since = this.getSince()
            const { data } = await this.axios.get(`stats/mostPlayedTracks/${since}/${offset}/${limit}`)
            if (!data.reasons) {
                const newTracks = data || []
                this.tracks = offset === 0 ? newTracks : this.tracks.concat(newTracks)
            } else {
                this.$notify('Fehler beim Laden der Stats: ', data.reasons)
            }
        },
        getSince () {
            if (this.since === 0 || this.activeTab === 'history') {
                return new Date(1000).getTime()
            }
            const utcString = new Date().toUTCString()
            return new Date(utcString).getTime() - this.since
        },
        loadMoreHistory () {
            this.loadHistory(this.history.length)
        },
        loadMoreArtists () {
            const currLength = this.artists.length
            if (currLength === 0) return
            this.loadMostPlayedArtists(currLength, 10)
        },
        loadMoreTracks () {
            const currLength = this.tracks.length
            if (currLength === 0) return
            this.loadMostPlayedTracks(currLength, 10)
        }
    }
}
</script>

<style lang="sass" scoped>
.tab-menu
    @apply flex flex-no-wrap bg-gray-800

    .tab-header-item
        @apply w-1/3 border-b-4 border-transparent p-2 cursor-pointer flex items-center justify-center text-center

        &:hover
            @apply bg-gray-700

        &.active
            @apply font-semibold bg-gray-700 text-white border-lime-600

.stat-chip
    @apply flex inline-flex rounded-full border-2 border-red-800 font-semibold overflow-hidden

    .key
        @apply py-2 px-3 bg-red-800 text-white w-40 text-center

    .value
        @apply py-2 px-3 w-20 text-center
</style>
