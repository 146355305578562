<template>
  <div id="app" class="bg-gray-900 text-white min-h-screen" :class="showTopNav ? 'pt-16' : 'pt-0'">
    <notification />
    <top-nav v-if="showTopNav" />
    <player v-if="showPlayer" />
    <router-view></router-view>
    <div
      v-if="false"
      v-show="this.scrollY > 0"
      class="fixed flex items-center justify-center z-20 rounded-full w-12 h-12 bg-blue-300 right-0 bottom-0 mr-10 mb-10 text-white"
      @click="goToTop"
      title="Nach oben"
    >
      <fa-icon icon="chevron-up" size="lg" />
    </div>
  </div>
</template>

<script>
import TopNav from './components/TopNav.vue'
import Player from './components/Player.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    TopNav, Player
  },
  computed: {
    ...mapState({
      loggedIn: state => state.user.loggedIn
    }),
    showPlayer () {
      return this.loggedIn && !['profile', 'search', 'confirmImage', 'theater', 'welcome', 'qr'].includes(this.$route.name)
    },
    showTopNav () {
      return this.loggedIn && !['confirmImage', 'theater', 'welcome'].includes(this.$route.name)
    }
  },
  data () {
    return {
      scrollY: 0
    }
  },
  mounted () {
    window.onscroll = () => {
      this.scrollY = window.scrollY
    }
  },
  methods: {
    goToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
