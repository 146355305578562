const MONTH = 30
const WEEK = 7
const DAY = 24
const HOUR = 60
const MIN = 60
const SEC = 1000

export default {
    methods: {
        toLocalTime (date, options = {}) {
            if (!date) return 'inaktiv'
            const inputDate = new Date(date)
            // max precision for relative: weeks
            if (options.relative) {
                let duration = new Date() - inputDate
                const ms = duration % SEC
                duration = (duration - ms) / SEC
                let s = duration % MIN
                duration = (duration - s) / MIN
                s = this.sec(s)
                let min = duration % HOUR
                duration = (duration - min) / HOUR
                min = this.min(min)
                let h = duration % DAY
                duration = (duration - h) / DAY
                h = this.hour(h)
                let d = duration % WEEK
                duration = (duration - d) / WEEK
                d = this.day(d)
                let w = duration % MONTH
                w = this.week(w)
                return `${w ? (w + ' ' + d) : d ? (d + ' ' + h) :
                    h ? (h + ' ' + min) : min ? min : s ? s : 'wenigen Sekunden'}`
            }
            const y = inputDate.getFullYear()
            const m = inputDate.getMonth() + 1
            const d = inputDate.getDate()
            const h = inputDate.getHours()
            const min = inputDate.getMinutes()
            const s = inputDate.getSeconds()
            return `${this.pre0(d)}.${this.pre0(m)}.${y} ${this.pre0(h)}:${this.pre0(min)}:${this.pre0(s)}`
        },
        toDuration (milliseconds, withUnit = false) {
            let duration = milliseconds
            const ms = duration % SEC
            duration = (duration - ms) / SEC
            let s = duration % MIN
            duration = (duration - s) / MIN
            let min = duration % HOUR
            duration = (duration - min) / HOUR
            let h = duration > 0 ? duration : false;
            const timestring = `${h ? h + ':' : ''}${min ? this.pre0(min) : '00'}:${this.pre0(s)}`;
            if (!withUnit) {
                return timestring;
            } else {
                return timestring + " " + (h ? "Stunden" : "Minuten");
            }
        },
        pre0 (dateType) {
            return `${dateType < 10 ? '0' + dateType : dateType}`
        },
        week (w) {
            return `${w > 0 ? w + ' Woche' + (w > 1 ? 'n' : '')    : ''}`
        },
        day (d) {
            return `${d > 0 ? d + ' Tag' + (d > 1 ? 'en' : '') : ''}`
        },
        hour (h) {
            return `${h > 0 ? h + ' Stunde' + (h > 1 ? 'n' : '') : ''}`
        },
        min (min) {
            return `${min > 0 ? min + ' Minute' + (min > 1 ? 'n' : '') : ''}`
        },
        sec (s) {
            return `${s > 0 ? s + ' Sekunde' + (s > 1 ? 'n' : '') : ''}`
        }
    }
}