<template>
    <div class="w-full text-white">
        <div
            v-show="!voteable"
            class="p-2 text-center font-bold bg-red-400"
        >
            Abstimmung zur Zeit deaktiviert
        </div>
        <div
            v-for="(track, i) in slicedResult"
            :key="track.id"
            class="flex flex-col"
            :class="{ 'bg-gray-700': showOptionsTrackId === track.id, 'border-t-2 border-gray-300': i > 0 }"
            :title="track.locked || track.artistLocked ? 'Song gesperrt' :
                        track.inTracklist ? 'Abstimmen' :
                            'Zur Abstimmung hinzufügen'"
        >
            <div
                class="flex flex-no-wrap justify-between items-center transition-colors duration-1000"
                @click="addToTracklist(track)"
                :class="{
                    'italic': computeCooldown(track.lastPlayback) > 0 || isCurrentOrNext(track.id),
                    'bg-green-600': voteStatus(track.id, submittedIds, revoteTooFastIds) === 'success'
                }"
            >
                <div class="w-full p-2 relative overflow-hidden" :class="track.locked || track.artistLocked ? 'cursor-default' : 'cursor-pointer'">
                    <track-card
                        :track="track"
                        size="sm"
                        :titleStyle="['tracking-wide', 'font-semibold', 'text-white']"
                        :artistStyle="['text-sm', 'font-normal', 'text-white']"
                        :lengthStyle="['font-normal']"
                        :showAlbum="false"
                    >
                        <div class="text-sm italic text-lime-500">
                            <p v-if="voteStatus(track.id, submittedIds, revoteTooFastIds) === 'success'">
                                Abgestimmt!
                            </p>
                            <p v-else-if="voteStatus(track.id, submittedIds, revoteTooFastIds) === 'revoteTooFast'" class="text-orange-500">
                                Du kannst alle {{ cooldown }} Minuten abstimmen
                            </p>
                            <p v-else-if="computeCooldown(track.lastPlayback) > 0">
                                Kürzlich gespielt - Warte {{computeCooldown(track.lastPlayback)}} Minuten
                            </p>
                            <p v-else-if="track.inTracklist">
                                Bereits in Abstimmung
                            </p>
                            <p v-else-if="isCurrentOrNext(track.id)">
                                Wird gespielt
                            </p>
                        </div>
                    </track-card>
                    <div v-if="track.locked || track.artistLocked" class="absolute flex items-center justify-center top-0 left-0 w-28 h-10 z-5 
                    transform -rotate-45 -translate-x-8 translate-y-1 bg-black">
                        <fa-icon icon="lock" />
                    </div>
                </div>
                <button
                    v-if="isAdmin"
                    class="hidden sm:block button-icon mr-2"
                    @click.stop="showOptions('button', track.id)"
                    title="Optionen"
                >
                    <fa-icon icon="ellipsis-v" />
                </button>
            </div>
            <div
                v-if="showOptionsTrackId === track.id"
                class="flex bg-gray-500 p-2 w-full items-center justify-around"
            >
                <button
                    v-if="isAdmin && (cherryPick === null || cherryPick.id !== track.id)"
                    class="button small gray mr-2"
                    @click.stop="chooseCherryPick(track.id)"
                >
                    Cherry Pick
                    <fa-icon icon="medal" class="ml-2" />
                </button>
                <button
                    v-if="isAdmin"
                    class="button small gray mr-2"
                    @click.stop="toggleLockTrack(track.id, track.locked)"
                    :disabled="lockingTrack"
                >
                    <template v-if="track.locked">
                        Song entsperren
                        <fa-icon icon="lock" class="ml-2" />
                    </template>
                    <template v-else>
                        Song sperren
                        <fa-icon icon="lock-open" class="ml-2" />
                    </template>
                </button>
                <button
                    class="button small gray"
                    v-if="isAdmin"
                    @click.stop="addToPlaylist(track)"
                    title="Einer Playlist hinzufügen"
                >
                    Zu Playlist hinzufügen
                    <fa-icon icon="plus" class="ml-2" />
                </button>
            </div>
        </div>
        <div
            v-show="slicedResult.length < searchResult.length"
            class="p-2 cursor-pointer text-center border-t-2 border-gray-200 hover:bg-gray-700"
            @click="$router.push('/search')"
        >
            Alle {{ searchResult.length }} Ergebnisse anzeigen
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TrackCard from './TrackCard.vue'
import voteStatusMixin from '../mixins/voteStatusMixin'
import OnLongPress from '../directives/OnLongPress'

export default {
    components: { TrackCard },
    mixins: [
        voteStatusMixin
    ],
    directives: {
        OnLongPress
    },
    computed: {
        ...mapState({
            isAdmin: state => state.user.isAdmin,
            voteable: state => state.admin.voteable,
            replayCooldown: state => state.admin.replayCooldown,
            searchResult: state => state.search.result,
            cherryPick: state => state.player.cherryPick,
            currentTrack: state => state.player.current,
            nextTrack: state => state.player.next,
            submittedIds: state => state.tracklist.submittedIds,
            cooldownIds: state => state.tracklist.cooldownIds,
            revoteTooFastIds: state => state.tracklist.revoteTooFastIds,
            cooldown: state => state.admin.revoteCooldown,
        }),
        slicedResult () {
            if (this.short) {
                return this.searchResult.slice(0, 4)
            }
            return this.searchResult
        }
    },
    props: {
        short: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showOptionsTrackId: -1,
            lockingTrack: false,
            preventVote: false
        }
    },
    methods: {
        addToTracklist (track) {
            if (this.preventVote) {
                this.preventVote = false
                return
            }
            if (track.locked) {
                this.$notify(`${track.name} ist zur Abstimmung gesperrt`)
            } else if (track.artistLocked) {
                this.$notify('Künstler ist zur Abstimmung gesperrt')
            } else if (this.computeCooldown(track.lastPlayback) < 1 && !this.isCurrentOrNext(track.id)) {
                this.$store.dispatch('tracklist/vote', track.id)
            }
        },
        async toggleLockTrack (trackId, locked) {
            this.lockingTrack = true
            const { data } = await this.axios.put('library/lockTrack',
                { trackId, locked: !locked })

            if (!data.reasons) {
                this.$store.commit('search/setTrackLocked', { id: trackId, locked: !locked })
            }
            this.lockingTrack = false
        },
        computeCooldown (lastPlayback) {
            if (lastPlayback === null) return 0
            lastPlayback = new Date(lastPlayback).getTime()
            return Math.floor((lastPlayback + this.replayCooldown * 60 * 1000 - Date.now()) / (1000 * 60))
        },
        showOptions (el, trackId) {
            if (el === 'container') {
                this.preventVote = true
            }
            if (this.showOptionsTrackId === trackId) {
                this.showOptionsTrackId = -1
            } else {
                this.showOptionsTrackId = trackId
            }
        },
        chooseCherryPick(trackId) {
            this.$store.dispatch('player/cherryPick', trackId)
        },
        addToPlaylist(track) {
            this.$store.commit('playlist/setTrackToAdd', track)
            this.$router.wrapPush({ name: 'addToPlaylist', params: { trackId:    track.id }} )
            this.$nextTick(() => {
                this.$emit('close')
            })
        },
        isCurrentOrNext (trackId) {
            return (this.currentTrack != null && this.currentTrack.id === trackId) || 
                (this.nextTrack != null && this.nextTrack.id === trackId)
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
