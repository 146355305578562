<template>
  <div class="mx-auto max-w-xl">
    <div class="mt-8 mb-2">
      <p v-if="searchResult.length === 0"
        class="text-center headline">
        Gib einen Suchbegriff ein, um Suchergebnisse zu erhalten
      </p>
      <p v-else class="text-center headline">Deine Suchergebnisse für &bdquo;{{ searchTerm }}&ldquo;</p>
    </div>
    <search-result />
    <div class="back-to-tracklist">
      <button
        class="button black round"
        @click="$router.push('/')"
      >
        Zurück zur Abstimmung
      </button>
    </div>
  </div>
</template>

<script>
import SearchResult from '../components/SearchResult.vue'
import { mapState } from 'vuex'

export default {
  components: {
    SearchResult
  },
  computed: {
    ...mapState({
      searchResult: state => state.search.result,
      searchTerm: state => state.search.term
    })
  },
  data () {
    return {

    }
  },
  methods: {
  }
}
</script>

<style lang="sass" scoped>
.result-item
  @apply px-3 py-1

  &:hover
    @apply bg-blue-500 text-white font-semibold cursor-pointer

.back-to-tracklist
  @apply fixed bottom-0 flex content-center justify-center mb-10

  left: 50%
  transform: translateX(-50%)
</style>
