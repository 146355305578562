<template>
      <div class="mx-auto max-w-lg">
         <!-- create or import -->
         <div class="horizontal-separator">
            <span />
            <p>Neue Playlist anlegen</p>
            <span />
         </div>
         <div class="flex justify-around">
            <div
               v-for="(reason, i) in reasons"
               :key="`reason-${i}`"
               class="chip orange"
            >
               <p>{{ formatReason(reason) }}</p>
               <button class="button transparent button-icon ml-2 transparent" @click="removeReasons">
                  <fa-icon icon="times" />
               </button>
            </div>
         </div>
         <!-- new playlist actions -->
         <div class="flex justify-around flex-wrap">
            <button
               class="button transparent button-icon not-round dark-gray w-48 mx-1 mb-2"
               @click="toggleAction('new')"
            >
               <p class="mr-2">Neu anlegen</p>
               <fa-icon icon="plus" />
            </button>
            <button
               class="button transparent button-icon not-round dark-gray w-48 mx-1 mb-2"
               :disabled="importingPlaylist"
               @click="toggleAction('import')"
            >
               <p class="mr-2">{{ importingPlaylist ? 'Importiere Playlist' : 'Importieren' }}</p>
               <fa-icon icon="spinner" v-if="importingPlaylist" />
               <fa-icon icon="file-upload" v-else />
            </button>
         </div>
         <!-- new playlist inputs -->
         <div class="flex justify-center items-center flex-wrap">
            <template v-if="showNewPlaylistInput">
               <input
                  id="newPlaylistInput"
                  v-model="newPlaylistName"
                  class="input-main w-64"
                  placeholder="Name eingeben"
                  type="text"
                  @keyup.enter="saveNewPlaylist()"
               />
               <div class="inline-block mx-2">
                  <button
                     class="button-icon"
                     @click="saveNewPlaylist()"
                  >
                     <fa-icon icon="check" />
                  </button>
                  <button
                     class="button-icon"
                     @click="toggleAction('new')"
                  >
                     <fa-icon icon="times" />
                  </button>
               </div>
            </template>
            <template v-if="showImportPlaylistInput">
               <input
                  id="importPlaylistInput"
                  v-model="importPlaylistUri"
                  class="input-main w-64"
                  placeholder="Playlist-URL einfügen"
                  type="text"
                  @keyup.enter="importPlaylist()"
               />
               <div class="inline-block mx-2">
                  <button
                     class="button-icon"
                     @click="importPlaylist()"
                  >
                     <fa-icon icon="check" />
                  </button>
                  <button
                     class="button-icon"
                     @click="toggleAction('import')"
                  >
                     <fa-icon icon="times" />
                  </button>
               </div>
            </template>
         </div>
         <!-- existing -->
         <div class="horizontal-separator">
               <span />
               <p>Gespeicherte Playlists</p>
               <span />
         </div>
         <playlist
            v-for="playlist in playlists"
            :key="playlist.id"
            :playlist="playlist"
         />
      </div>
</template>

<script>
import { mapState } from 'vuex'
import Playlist from '../components/Playlist.vue'

export default {
   components: { Playlist },
   computed: {
      ...mapState({
         reasons: state => state.playlist.reasons,
         playlists: state => state.playlist.available,
      }),
      playlistName: {
         get () {
               return this.$store.state.admin.playlistName
         },
         set (value) {
               this.delaySafe()
               this.$store.commit('admin/setPlaylistName', value)
         }
      }
   },
   beforeMount () {
      this.$store.dispatch('playlist/loadAvailable')
   },
   data () {
   return {
      showNewPlaylistInput: false,
      showImportPlaylistInput: false,
      newPlaylistName: '',
      importPlaylistUri: '',
      importingPlaylist: false
   }
   },
   methods: {
      toggleAction(action) {
         if (action === 'new') {
            this.showNewPlaylistInput = !this.showNewPlaylistInput
            this.showImportPlaylistInput = false
         } else if (action === 'import') {
            this.showImportPlaylistInput = !this.showImportPlaylistInput
            this.showNewPlaylistInput = false
         }
      },
      async saveNewPlaylist () {
         if (this.newPlaylistName.length < 2) return
         const reasonsBefore = this.reasons.length;
         await this.$store.dispatch('playlist/new', { name: this.newPlaylistName })
         if (this.reasons.length === reasonsBefore) {
            // No new reason added => dispatch was successful
            this.newPlaylistName = '';
         }
      },
      async importPlaylist() {
         if (this.importPlaylistUri.length === 0) return

         const reasonsBefore = this.reasons.length;
         this.importingPlaylist = true
         await this.$store.dispatch('playlist/importFromSpotify', this.importPlaylistUri)
         this.importingPlaylist = false
         if (this.reasons.length === reasonsBefore) {
            // No new reason added => dispatch was successful
            this.importPlaylistUri = '';
         }
      },
      formatReason (reason) {
         if (reason === 'name') {
               return 'Name bereits vergeben oder ungültig'
         } else if (reason === 'id') {
               return 'Playlist nicht gefunden'
         } else if (reason === 'trackId') {
               return 'Ungültiger Song'
         } else if (reason === 'uri') {
               return 'Ungültige Playlist-URI'
         } else if (reason === 'existing') {
               return 'Playlist existiert bereits'
         } else {
               return reason
         }
      },
      removeReasons () {
         this.$store.commit('playlist/resetReasons')
      }
   }
}
</script>

<style lang="sass" scoped>

</style>
