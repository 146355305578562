<template>
  <div class="mx-auto sm:w-128 w-80">
    <p class="my-2 text-center text-lg font-semibold">Scanne den QR-Code,<br>um ein anderes Gerät einzuloggen</p>
    <div v-if="generatingQr">
      <loader loadingText="Generiere QR-Code" />
    </div>
    <div
      id="qr-container"
      class="flex justify-center bg-circuit py-2 mb-12"
    >
    </div>
    <!--
    <button
      class="button gray w-full mt-2"
      :disabled="generatingQr"
      @click="generateCode()"
    >
      QR-Code neu laden
    </button>
    -->
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  computed: {
    token  () {
      return this.$store.state.admin.sessionToken
    }
  },
  watch: {
    token (newToken, oldToken) {
      if (newToken !== oldToken) {
        this.generateCode()
      }
    }
  },
  data () {
    return {
      generatingQr: false
    }
  },
  mounted () {
    this.generateCode()
  },
  methods: {
    async generateCode () {
      this.generatingQr = true

      const qrContainer = document.getElementById('qr-container')

      while (qrContainer.firstChild) {
        qrContainer.removeChild(qrContainer.firstChild)
      }

      try {
        await QRCode.toCanvas(window.location.protocol + "//" + window.location.hostname + `/login?token=${this.token}`,
          { errorCorrectionLevel: 'H', width: 300 },
          (error, canvas) => {
            if (error) throw error

            this.generatingQr = false
            qrContainer.appendChild(canvas)
          })
      } catch (error) {
        this.generatingQr = false
        console.log('da ging was schief: ', error)
      }
    }
  }
}
</script>