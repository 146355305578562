<template>
  <div>
    <div class="relative w-full">
      <input
        type="text"
        class="input-main w-full"
        placeholder="Nach Playlist suchen"
        v-model="searchTerm"
      />
      <div class="input-actions">
        <button
          v-if="searchTerm !== ''"
          class="button transparent button-icon"
          @click="searchTerm = ''"
        >
          <fa-icon icon="times" />
        </button>
      </div>
    </div>
    <div
      v-for="playlist in filteredPlaylists"
      :key="playlist.id"
      class="relative flex justify-center content-center cursor-pointer my-2 p-2 bg-gray-200 shadow-lg"
      @click="$emit('select', playlist.id)"
    >
      <div class="absolute left-0 top-0 ml-2 transform translate-y-2 text-green-spotify">
        <fa-icon
          v-if="playlist.uri"
          :icon="['fab', 'spotify']"
          title="Spotify-Playlist"
        />
      </div>
      <p class="mx-8 font-semibold text-center">{{ playlist.name }}</p>
    </div>
    <div
      v-if="filteredPlaylists.length === 0"
      class=""
    >
      Keine Playlist vorhanden
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    tracks: {
      type: Array,
      default: () => ([])
    },
    redirect: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      playlists: state => state.playlist.available
    }),
    filteredPlaylists () {
      return this.playlists.filter(playlist => {
        if (this.searchTerm === '') return true
        const searchRegex = new RegExp(this.searchTerm.trim().toLowerCase(), 'g')
        return playlist.name.toLowerCase().search(searchRegex) > -1
      })
    }
  },
  data () {
    return {
      searchTerm: ''
    }
  },
  mounted () {
    this.$store.dispatch('playlist/loadAvailable')
  },
  methods: {

  }
}
</script>

<style lang="sass" scoped>

</style>
