<template>
  <div
    class="relative flex items-center border-2 border-gray-700 px-2 py-1"
    :class="`w-${width}`"
  >
    <p class="font-semibold">
      {{ selected ? (selected.name || selected.value) : 'Option wählen' }}
    </p>
    <div class="absolute h-full top-0 right-0">
      <button class="button transparent button-icon mr-1" @click.stop="toggleMenu()">
        <fa-icon :icon="menuVisible ? 'chevron-up' : 'chevron-down'" />
      </button>
    </div>
    <div
      v-show="menuVisible"
      class="dropdown-menu"
      :class="`w-${width}`"
    >
      <div
        v-for="option in options"
        :key="option.key"
        class="dropdown-option"
        :class="{ selected: selected ? option.value === selected.value : false }"
        @click="select(option)"
      >
        {{ option.name || option.value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      valdator (optionsList) {
        return optionsList.every(option => (option.key && option.value))
      }
    },
    preselect: {
      type: Object,
      validator (option) {
        return 'key' in option && 'value' in option
      }
    },
    size: {
      type: String,
      default: 'md',
      validator (value) {
        return ['sm', 'md', 'lg'].includes(value)
      }
    }
  },
  computed: {
    width () {
      return this.size === 'sm' ? '32' : (this.size === 'lg' ? '128' : '64')
    }
  },
  data () {
    return {
      menuVisible: false,
      selected: null
    }
  },
  mounted () {
    if (this.preselect) {
      this.selected = this.preselect
    }
  },
  methods: {
    toggleMenu () {
      if (this.menuVisible) {
        this.hideMenu()
      } else {
        this.showMenu()
      }
    },
    showMenu () {
      // window.addEventListener('click', closeMenu())
      this.menuVisible = true
    },
    hideMenu () {
      if (this.menuVisible) this.menuVisible = false
    },
    select (option) {
      if (this.selected.key !== option.key) {
        this.selected = option
        this.$emit('change', option)
      }
      this.hideMenu()
    }
  }
}
</script>

<style lang="sass" scoped>
.dropdown-menu
  @apply absolute top-0 right-0 bg-gray-100 rounded transform translate-x-1 translate-y-10 z-10
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.7)

  .dropdown-option
    @apply px-3 py-1 flex flex-no-wrap items-center cursor-pointer

    &.selected
      @apply font-semibold

    &:hover
      @apply bg-gray-400

      &:first-child
        @apply rounded-t

      &:last-child
        @apply rounded-b
</style>
