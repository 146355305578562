<template>
    <div class="flex justify-center">
        <div class="flex flex-col">
            <div
                v-for="trackEntry in tracks"
                :key="trackEntry.id"
                class="flex flex-col bg-gray-700 mt-2"
            >
                <div class="flex justify-between bg-gray-800 px-2 py-1 font-semibold">
                    <p>{{ trackEntry.count }} mal abgespielt</p>
                    <p>zuletzt {{ toLocalTime(trackEntry.track.lastPlayback) }}</p>
                </div>
                <div class="p-2">
                    <track-card
                        :track="trackEntry.track"
                        size="md"
                    />
                </div>
            </div>
            <div class="my-2">
                <button v-if="tracks.length > 0" class="button green w-full"
                    @click="$emit('load-more')"
                >
                    Mehr Laden
                </button>
                <p v-else>
                    Keine Songs im gewählten Zeitraum
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import TrackCard from './TrackCard.vue'
import timeFormatter from '../mixins/timeFormatter'

export default {
    components: {
        TrackCard
    },
    mixins: [ timeFormatter ],
    props: {
        tracks: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
