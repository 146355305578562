import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faMinus, faSave, faArrowsAltH, faAlignJustify, faTrashAlt,
  faTimes, faBold, faItalic, faStrikethrough, faUnderline, faCode, faParagraph,
  faListUl, faListOl, faQuoteLeft, faUndo, faRedo, faSearch, faEdit, faHeart,
  faHeartBroken, faThumbsUp, faTrash, faCaretSquareDown, faCheck, faPlay,
  faInfoCircle, faSyncAlt, faFileUpload, faSpinner, faLock, faLockOpen, faPause,
  faStop, faChevronUp, faStepForward, faChartLine, faBars, faCog, faCrown, faQrcode,
  faMusic, faStream, faUsersCog, faChevronDown, faUser, faDoorOpen, faBackspace, faRandom,
  faUnlock, faEllipsisV, faMedal, faVolumeOff, faVolumeDown, faVolumeUp, faVolumeMute,
  faIcons, faDesktop, faUserTie, faCamera, faArrowRight, faImage, faQuestion }
  from '@fortawesome/free-solid-svg-icons'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notification from './plugins/notification/'
import Loader from './elements/Loader.vue'

// application structure
import routes from './routes.js'
import store from './store'
import '@/assets/styles/main.sass'
import '@/assets/styles/button.sass'
import '@/assets/styles/form.sass'
import '@/assets/styles/typography.sass'
import '@/assets/styles/bgPattern.sass'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(Notification)

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://client.hitparade.leinweber.io'

// error handling
axios.interceptors.response.use(response => {

  if (Array.isArray(response.data)) {
    // console.log('response data is array! Check array')
  } else if (response.data) {
    // console.log('response data is available')
  }
  return response
}, error => {
  if (error.message === 'Network Error') {
    Vue.notify('Verbindung zum Server nicht möglich', {
      title: 'Netzwerkfehler',
      type: 'error'
    })
  }
  return Promise.reject(error)
})

// whitelist fa icons
library.add(faPlus, faMinus, faSave, faArrowsAltH, faAlignJustify, faTrashAlt,
    faTimes, faBold, faItalic, faStrikethrough, faUnderline, faCode,
    faParagraph, faListUl, faListOl, faQuoteLeft, faUndo, faRedo, faSearch,
    faEdit, faHeart, faHeartBroken, faThumbsUp, faTrash, faCaretSquareDown,
    faCheck, faPlay, faInfoCircle, faSpotify, faSyncAlt, faFileUpload, faSpinner,
    faLock, faLockOpen, faPause, faStop, faChevronUp, faChevronDown, faStepForward,
    faChartLine, faBars, faCog, faCrown, faQrcode, faMusic, faStream, faUsersCog,
    faUser, faDoorOpen, faBackspace, faRandom, faUnlock, faEllipsisV, faMedal,
    faVolumeOff, faVolumeDown, faVolumeUp, faVolumeMute, faIcons, faDesktop, faUserTie,
    faCamera, faArrowRight, faImage, faQuestion)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('loader', Loader)

// check if user has a valid session
store.commit('user/setCheckingAuth')
store.dispatch('user/checkAuthStatus')

// import routes
const router = new VueRouter(routes)
router.wrapPush = (location) => {
  router.push(location).catch((failure) => {
    if(!VueRouter.isNavigationFailure(failure, VueRouter.NavigationFailureType.duplicated)) {
      throw failure
    }
  });
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page
    if (!store.state.user.loggedIn) {
      next({
        path: '/login',
        query: to.fullPath != '/theater' ? { redirect: to.fullPath } : null
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? `${to.meta.title} - Hitbox` : 'Hitbox'
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
