<template>
  <div class="fixed bottom-0 mx-auto py-2 px-4 bg-gray-700 shadow-lg z-20 w-80 sm:w-128 left-1/2">
    <div class="headline">
      {{ session.name ? ('Bearbeiten: ' + session.name) : 'Neuen Account erstellen' }}
    </div>
    <div class="mt-2 flex items-center">
      <div class="sm:w-1/3 w-1/2">
        <label for="new-session-name-input" class="label-inline sm:text-base text-sm whitespace-no-wrap">
          {{ session.id > -1 ? 'Neuer ' : '' }}Name
        </label>
      </div>
      <div class="sm:w-2/3 w-1/2">
        <input
          id="new-session-name-input"
          class="input-main-unsized sm:py-2 py-0 sm:px-3 px-1 sm:text-base text-sm sm:w-64 w-36"
          :class="{ error: nameInvalid }"
          type="text"
          :placeholder="`${session.id > -1 ? 'Neuen ': ''} Namen eingeben`"
          v-model="newName"
        />
        <p class="text-sm italic" v-if="nameInvalid">Name ungültig</p>
      </div>
    </div>
    <div
      v-if="isAdmin"
      class="mt-2 flex items-center"
    >
      <div class="sm:w-1/3 w-1/2">
        <label for="new-session-password-input" class="label-inline sm:text-base text-sm whitespace-no-wrap">
          {{ session.id > -1 ? 'Neues ' : '' }}Passwort
        </label>
      </div>
      <div class="sm:w-2/3 w-1/2">
        <input
          id="new-session-password-input"
          class="input-main-unsized sm:py-2 py-0 sm:px-3 px-1 sm:text-base text-sm sm:w-64 w-36"
          :class="{ error: passwordInvalid }"
          type="password"
          :placeholder="`${session.id > -1 ? 'Neues': ''} Passwort eingeben`"
          v-model="newPassword"
          @input="checkPasswords"
        />
      </div>
    </div>
    <div
      v-if="isAdmin"
      class="mt-2 flex items-center"
    >
      <div class="sm:w-1/3 w-1/2">
        <label for="new-session-password-confirm-input" class="label-inline sm:text-base text-sm whitespace-no-wrap">
          Passwort bestätigen
        </label>
      </div>
      <div class="sm:w-2/3 w-1/2">
        <input id="new-session-password-confirm-input"
          class="input-main-unsized sm:py-2 py-0 px-1 sm:px-3 text-sm sm:text-base w-36 sm:w-64"
          :class="{ error: passwordInvalid }"
          type="password" placeholder="Passwort wiederholen"
          v-model="newPasswordConfirm"
          @input="checkPasswords"
        />
        <p v-if="passwordInvalid" class="text-sm italic">Passwort ungültig</p>
      </div>
    </div>
    <div class="mt-4 flex justify-center">
      <button
        v-if="session.id !== -1"
        class="button-text w-64 mr-2"
        :class="{ red: deletingSession }"
        @click="deleteSession(session.id)"
      >
        {{ deletingSession ? 'Löschen bestätigen' : 'Löschen' }}
      </button>
      <button
        class="button lime w-1/3"
        @click="save()"
      >
        Speichern
      </button>
    </div>
    <div class="absolute top-0 right-0 mt-2 mr-2">
      <button
        class="button transparent button-icon"
        @click="close()"
      >
        <fa-icon icon="times" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    session: {
      type: Object,
      required: true
    }
  },
  watch: {
    session (newSession, oldSession) {
      if (newSession.name !== oldSession.name) {
        this.preset()
      }
    }
  },
  mounted () {
    this.preset()
  },
  computed: {
    nameInvalid () {
      return this.$store.state.admin.reasons.includes('name')
    },
    passwordInvalid () {
      return this.passwordsDiffer || this.$store.state.admin.reasons.includes('password')
    },
    deletingSession () {
      return this.confirmDeleteSessionId === this.session.sessionId
    }
  },
  data () {
    return {
      debounce: null,
      confirmDeleteSessionId: null,
      newName: '',
      newPassword: '',
      newPasswordConfirm: '',
      passwordsDiffer: false
    }
  },
  methods: {
    preset () {
      this.newName = this.session.name
      document.getElementById('new-session-name-input').focus()
    },
    resetEditingSession () {
      this.newName = '',
      this.newPassword = '',
      this.newPasswordConfirm = '',
      this.passwordsDiffer = false
    },
    deleteSession (id) {
      if (this.confirmDeleteSessionId === id) {
        console.log('confirmed to delete session [not implemented yet]: ', this.confirmDeleteSessionId)
        this.$emit('delete', this.confirmDeleteSessionId)
        this.confirmDeleteSessionId = null
      } else {
        this.confirmDeleteSessionId = id
      }
    },
    save () {
      if (this.newName === '' && this.newPassword === '') return
      this.checkPasswords()
      if (this.passwordsDiffer) return
      // edit admin mode
      if (this.isAdmin) {
        const modifiedAdmin = { name: this.newName }
        // new admin creation
        if (this.session.id === -1) {
          if (this.newPassword === '') {
            this.passwordsDiffer = true
            return
          }
          modifiedAdmin['password'] = this.newPassword
          modifiedAdmin['image'] = ''
          this.$store.dispatch('admin/createAdmin', modifiedAdmin)
        }
        // modify existing admin
        else {
          modifiedAdmin['id'] = this.session.id
          // change password if desired
          if (this.newPassword !== '') {
            modifiedAdmin['password'] = this.newPassword
          }
          if (modifiedAdmin.id === this.$store.state.user.adminId) {
            this.$store.dispatch('user/rename', modifiedAdmin.name)
            delete modifiedAdmin.name
          }
          this.$store.dispatch('admin/saveAdmin', modifiedAdmin)
        }
      }
      // edit session mode
      else {
        const modifiedSession = { id: this.session.sessionId, name: this.newName }
        this.$store.dispatch('admin/saveSession', modifiedSession)
      }
      this.close()
    },
    checkPasswords () {
      if (this.debounce) clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        if (this.newPassword === '' || this.newPasswordConfirm === '') return
        if (this.newPassword !== this.newPasswordConfirm) {
          this.passwordsDiffer = true
        } else {
          this.passwordsDiffer = false
        }
        this.debounce = null
      }, 500)
    },
    close () {
      this.resetEditingSession()
      this.$store.commit('user/resetEditingSessionId')
    }
  }
}
</script>

<style lang="sass" scoped>
</style>
