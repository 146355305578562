<template>
    <div
        id="song-search"
        class="relative w-full mx-auto"
    >
        <div class="flex bg-white">
            <input
                type="text"
                id="songSearchInput"
                v-model="search"
                :placeholder="voteable ? 'Musik suchen...' : 'Abstimmung deaktiviert'"
                class="input-main w-full"
                :disabled="!voteable"
                :class="!voteable ? ['disabled placeholder-red-900'] : null"
                @input="delayLookup()"
                @keyup.enter="lookup(true)"
                @keyup.esc="closeSearchResult()"
                @focus="initSearch()"
            />
            <div class="w-auto flex justify-end items-center p-1">
                <loader
                v-show="$store.state.search.searching"
                theme="light"
                class="mr-2"
                />
                <button
                v-show="isSearchActive()"
                class="button transparent black button-icon"
                title="Suche schließen"
                @click="resetSearchResult()"
                >
                <fa-icon icon="times" />
                </button>
                <button
                v-show="voteable && !isSearchActive()"
                class="button transparent black button-icon"
                @click="focusSongSearch()"
                >
                <fa-icon icon="search" />
                </button>
            </div>
        </div>
        <!-- give shortened list of results -->
        <div
            v-show="searchResultVisible && searchResult.length > -1 && $route.path !== '/search'"
            class="absolute w-full bg-gray-900 rounded-md min-w-sm shadow-2xl overflow-hidden"
        >
            <search-result
                :short="true"
                @close="closeSearchResult()"
            />
        </div>
    </div>
</template>

<script>
import SearchResult from './SearchResult.vue'
import { mapState } from 'vuex'

export default {
    components: {
        SearchResult
    },
    computed: {
        ...mapState({
            searchTerm: state => state.search.term,
            voteable: state => state.admin.voteable
        }),
        search: {
            get () {
                return this.searchTerm
            },
            set (value) {
                this.$store.commit('search/setTerm', value)
            }
        },
        searchResult () {
            return this.$store.state.search.result
        }
    },
    data () {
        return {
            delayRequest: null,
            searchResultVisible: false
        }
    },
    mounted () {
        window.addEventListener('click', () => {
            if (this.searchResultVisible) {
                if (this.searchTerm.length === 0) {
                this.resetSearch()
                }
                this.searchResultVisible = false
            }
        })

        document.getElementById('song-search').addEventListener('click', event => {
            event.stopPropagation()
        })
    },
    methods: {
        initSearch () {
            this.searchResultVisible = true
        },
        focusSongSearch () {
            document.getElementById('songSearchInput').focus()
        },
        delayLookup () {
            if (this.delayRequest) clearTimeout(this.delayRequest)

            this.delayRequest = setTimeout(() => {
                this.lookup()
            }, 1500)
        },
        lookup (blur = false) {
            if (this.delayRequest) {
                clearTimeout(this.delayRequest)
                this.delayRequest = null
            }
            if (this.searchTerm.length > 1) {
                this.$store.dispatch('search/lookup')
                if (blur) {
                this.getSongSearchInput().blur()
                }
            } else if (this.searchTerm.length === 1) {
                this.$notify('Bitte mindestens zwei Zeichen eingeben', {
                type: 'warning'
                })
            } else {
                this.resetSearch()
            }
        },
        getSongSearchInput() {
            return document.getElementById('songSearchInput');
        },
        isSearchActive() {
            return this.searchResultVisible || this.searchTerm.length > 0 || (document.activeElement == this.getSongSearchInput());
        },
        closeSearchResult() {
            this.searchResultVisible = false
            this.resetSearch()
            this.getSongSearchInput().blur()
        },
        resetSearchResult () {
            this.searchResultVisible = false
            this.resetSearch()
        },
        resetSearch () {
            this.$store.commit('search/reset')
        }
    }
}
</script>

<style lang="sass" scoped>

</style>
