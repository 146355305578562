<template>
    <div class="relative h-screen overflow-hidden">
        <div class="h-full m-4 grid grid-flow-col gap-4 auto-cols-fr auto-rows-fr grid-rows-3">
            <!--current track-->
            <div class="row-span-3">
                <div v-if="current" class="flex flex-col items-center justify-center">
                    <div v-if="currentTrackImage" class="w-10/12">
                        <img class="w-full h-full" :src="currentTrackImage" />
                    </div>
                    <div v-if="elapsed >= 0" class="w-10/12 px-2 my-2">
                        <progress-bar
                            :valueMin="0"
                            :valueMax="current.length"
                            :valueNow="elapsed"
                        />
                    </div>
                    <div class="w-full px-4">
                        <div class="flex flex-wrap mb-3 tracking-wide text-xl font-semibold" style="font-variant: small-caps">
                            <span
                                v-for="(artist, i) in current.artists"
                                :key="`current-artist-${i}`"
                                class="flex flex-no-wrap content-center"
                            >
                                <p>
                                    {{ artist }}
                                </p>
                                <span v-if="i < current.artists.length - 1" class="h-4/5 my-auto mx-1 border-1 border-gray-400" />
                            </span>
                        </div>
                        <p class="mb-3 tracking-wide text-3xl font-bold">
                            {{ current.name }}
                        </p>
                        <p class="tracking-wide text-xl">
                            {{ current.album }}
                        </p>
                    </div>
                    <div class="flex flex-wrap justify-center mt-6 flex-row-reverse items-end -space-x-2 space-x-reverse">
                        <voter-chip v-for="(voter, i) of unifiedVotes(currentTrackVotes, true)"
                            :key="`current-voter-${i}`"
                            :voter="voter"
                            size="lg"
                        />
                    </div>
                </div>
                <div v-else class="flex h-1/3 tracking-wide text-5xl font-semibold text-gray-400 items-center" style="font-variant: small-caps">
                    <p class="w-full text-center">Wiedergabe gestoppt</p>
                </div>
            </div>
            <!--next track-->
            <div class="row-span-1 col-span-2 grid gap-2 grid-cols-4 auto-cols-max">
                <div class="col-span-3">
                    <p class="ml-0 mb-2 tracking-wide text-4xl font-semibold" style="font-variant: small-caps">
                        {{ next ? 'Als nächstes:' : (status === 'playing' ? 'Kein nächster Song' : '') }}
                    </p>
                    <div v-if="next" class="h-48 flex space-x-4 items-center">
                        <div v-if="nextTrackImage" class="flex-shrink-0 h-full">
                            <img class="w-full h-full" :src="nextTrackImage" />
                        </div>
                        <div>
                            <div class="flex flex-wrap mb-2 tracking-wide font-semibold" style="font-variant: small-caps">
                                <span
                                    v-for="(artist, i) of next.artists"
                                    :key="`next-artist-${i}`"
                                    class="flex flex-no-wrap content-center"
                                >
                                    <p class="truncate">
                                        {{ artist }}
                                    </p>
                                    <span v-if="i < next.artists.length - 1" class="h-4/5 my-auto mx-1 border-1 border-gray-400" />
                                </span>
                            </div>
                            <p class="mb-2 tracking-wide text-xl font-bold">
                                {{ next.name }}
                            </p>
                            <p class="mb-2 tracking-wide truncate">
                                {{ next.album }}
                            </p>
                            <p class="tracking-wide text-gray-400">
                                {{ toDuration(next.length) }}
                            </p>
                        </div>
                    </div>
                    <div v-if="next" class="flex flex-no-wrap">
                        <div class="mt-4 flex flex-row-reverse items-end -space-x-2 space-x-reverse">
                            <voter-chip v-for="(voter, i) of unifiedVotes(nextTrackVotes, true)"
                                :key="`next-voter-${i}`"
                                :voter="voter"
                                size="base"
                            />
                        </div>
                    </div>
                </div>
                <div class="my-auto mx-auto">
                    <img class="w-48 h-48" src="../assets/image/logoWhite.svg" />
                </div>
            </div>
            <!--tracklist-->
            <div class="row-span-2 col-span-1 lg:col-span-2">
                <div
                    v-if="cherryPick"
                    class="flex items-center w-full mb-2 transition-colors duration-1000 p-2 bg-gradient-to-r from-yellow-600"
                    title="Dieser Song wurde von einem Administrator als nächster Titel bestimmt"
                >
                    <div class="flex-shrink-0 w-32 text-center">
                        <fa-icon icon="medal" class="ml-2" size="2x" />
                    </div>
                    <track-card
                        :track="cherryPick"
                        size="md"
                        :titleStyle="[ 'tracking-wide', 'font-semibold', 'text-white' ]"
                        :artistStyle="[ 'text-sm', 'text-white' ]"
                        :albumStyle="[ 'text-sm', 'text-white' ]"
                        durationPos="bottom"
                    />
                </div>
                <div v-for="(track, i) of tracklist"
                    :key="track.id"
                    class="flex items-center w-full mb-2 transition-colors duration-1000 p-2 bg-gradient-to-r from-gray-700"
                    :class="{'bg-lime-500': (trackDiff.includes(i)), 'from-yellow-600': isFirstPos(i)}"
                >
                    <p class="flex-shrink-0 w-32 font-mono whitespace-no-wrap text-4xl"># {{ pre00(i + 1) }}</p>
                    <track-card
                        :track="track"
                        size="md"
                        :titleStyle="[ 'tracking-wide', 'font-semibold', 'text-white' ]"
                        :artistStyle="[ 'text-sm', 'text-white' ]"
                        :albumStyle="[ 'text-sm', 'text-white' ]"
                        durationPos="bottom"
                    />
                    <div class="w-full flex-no-wrap overflow-hidden items-center h-18">
                        <div v-if="track.votes.length > 0" class="flex flex-row-reverse items-end -space-x-2 space-x-reverse">
                            <voter-chip v-for="(voter, i) of unifiedVotes(track.votes, true)"
                                :key="`track-${track.id}-voter-${i}`"
                                :voter="voter"
                                size="base"
                            />
                        </div>
                    </div>
                </div>
                <p v-if="remainingTracklistLength" class="mt-5 tracking-wide" style="font-variant: small-caps">Noch {{ toDuration(remainingTrackTime, true) }} Musik folgen!</p>
            </div>
        </div>
        <div class="absolute z-10 bottom-0 right-0">
            <button class="button-icon" @click="fullScreen()">
                <fa-icon icon="times" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import voteUnifier from '../mixins/voteUnifier'
import TrackCard from '../components/TrackCard.vue'
import ProgressBar from '../elements/ProgressBar.vue'
import VoterChip from '../elements/VoterChip.vue'
import timeFormatter from '../mixins/timeFormatter'
import numberFormatter from '../mixins/numberFormatter'

export default {
    components: { TrackCard, ProgressBar, VoterChip },
    computed: {
        ...mapState({
            current: state => state.player.current,
            currentTrackImage: state => state.player.currentTrackImage,
            currentTrackVotes: state => state.player.currentTrackVotes,
            elapsed: state => state.player.elapsed,
            next: state => state.player.next,
            nextTrackImage: state => state.player.nextTrackImage,
            nextTrackVotes: state => state.player.nextTrackVotes,
            status: state => state.player.status,
            tracklist: state => {
                    const len = state.player.cherryPick ? 4 : 5
                    if (state.tracklist.tracks.length > len) {
                        return state.tracklist.tracks.slice(0, len)
                    }
                    return state.tracklist.tracks
                },
            remainingTracklistLength: state => Math.max(state.tracklist.tracks.length - (state.player.cherryPick ? 4 : 5), 0),
            remainingTrackTime: state => {
                let time = 0;
                let i = state.player.cherryPick ? 4 : 5;
                for (i; i < state.tracklist.tracks.length; i++) {
                    time += state.tracklist.tracks[i].length;
                }
                return time;
            },
            trackDiff: state => state.tracklist.diff,
            profileImages: state => state.user.sessionImages,
            cherryPick: state => state.player.cherryPick
        })
    },
    mixins: [
        voteUnifier,
        timeFormatter,
        numberFormatter
    ],
    data() { 
        return {
            exitFullscreenHandled: false
        }
    },
    async mounted () { 
        this.$store.dispatch('player/poll', true)
        await this.$store.dispatch('tracklist/load', { diff: true, numDiffTracks: 10 })
        this.$store.dispatch('tracklist/poll', { diff: true, isTheater: true })
        await this.$store.dispatch('player/loadStatus', true)
        this.fullScreen()

        let sessionIds = this.tracklist.reduce((res, acc) => {
            const trackSessionIds = acc.votes.reduce((res2, acc2) => {
                if (!res.includes(acc2.id)) {
                    res2.push(acc2.id)
                }
                return res2
            }, [])
            res = res.concat(trackSessionIds)
            return res
        }, [])
        
        if (this.current) sessionIds = sessionIds.concat(this.current.votes.map(v => v.id))
        if (this.next) sessionIds = sessionIds.concat(this.next.votes.map(v => v.id))

        this.$store.dispatch('user/loadSessionImages', sessionIds)
        window.addEventListener('webkitfullscreenchange', this.exitFullscreenHandler)
        window.addEventListener('mozfullscreenchange', this.exitFullscreenHandler)
        window.addEventListener('fullscreenchange', this.exitFullscreenHandler)
        window.addEventListener('MSFullscreenChange', this.exitFullscreenHandler)
    },
    destroyed () {
        this.$store.dispatch('tracklist/clearPoll')
    },
    methods: {
        goBack(){
            if (window.history.length > 0){
                this.$router.go(-1)
            }
            else {
                this.$router.push('/')
            }
        },
        fullScreen(){
            if (!document.fullscreenElement){
                document.documentElement.requestFullscreen()
                this.exitFullscreenHandled = false
            } else {
                if (document.exitFullscreen){
                    document.exitFullscreen()
                }
            }
        },
        exitFullscreenHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                if (!this.exitFullscreenHandled) {
                    this.exitFullscreenHandled = true
                    this.goBack()
                    window.removeEventListener('webkitfullscreenchange', this.exitFullscreenHandler)
                    window.removeEventListener('mozfullscreenchange', this.exitFullscreenHandler)
                    window.removeEventListener('fullscreenchange', this.exitFullscreenHandler)
                    window.removeEventListener('MSFullscreenChange', this.exitFullscreenHandler)
                }
            }
        },
        isFirstPos(index) {
            return index === 0 && !this.cherryPick ? true : false
        }
    }
}
</script>

<style lang="sass" scoped>
.pulse
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1)
    transform: scale(1)
    animation: pulse 2s linear 0s 1 normal none paused

    &.animate
        animation-play-state: running

@keyframes pulse
    0%
        transform: scale(0.95)
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7)

    70%
        transform: scale(1)
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0)

    100%
        transform: scale(0.95)
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0)
</style>
