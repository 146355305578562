export default {
    methods: {
        unifiedVotes (votes, reverse = false) {
            let flatMap = []
            for (const vote of votes) {
                const index = flatMap.findIndex(entry => entry.id === vote.id)
                if (index > -1) {
                    flatMap[index].count += 1
                } else {
                    flatMap.push({ ...vote, count: 1 })
                }
            }
            if (reverse) {
                return flatMap.sort((a, b) => (a.count <= b.count ? -1 : 1))
            }
            return flatMap.sort((a, b) => (a.count > b.count ? -1 : 1))
        }
    }
}