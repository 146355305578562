<template>
    <div class="space-y-2">
        <div class="bg-black rounded-full overflow-hidden">
            <div class="bg-lime-400" :class="`h-${barThickness}`" :style="{ width: progress }" role="progressbar" :aria-valuenow="valueNow" :aria-valuemin="valueMin" :aria-valuemax="valueMax"></div>
        </div>
        <div class="text-gray-400 flex justify-between font-medium tabular-nums">
            <div>{{ toDuration(valueNow) }}</div>
            <div>{{ toDuration(valueMax) }}</div>
        </div>
    </div>
</template>

<script>
import timeFormatter from '../mixins/timeFormatter'

export default {
    props: {
        valueNow: {
            type: Number,
            default: () => 20
        },
        valueMin: {
            type: Number,
            default: () => 0
        },
        valueMax: {
            type: Number,
            default: () => 100
        },
        barThickness: {
            type: String,
            default: () => '2'
        }
    },
    mixins: [
        timeFormatter
    ],
    computed: {
        progress () {
            return `${Math.floor((this.valueNow / this.valueMax) * 100)}%`
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="sass" scoped>

</style>
