<template>
    <div class="mx-auto w-80 sm:w-128">
        <div class="inline flex items-center justify-center mt-2">
            <template v-if="editingName">
                <input
                    id="newNameInput"
                    type="text"
                    class="input-inline"
                    v-model="newName"
                    placeholder="Neuen Anzeigenamen eingeben"
                    @keyup.enter="saveName()"
                    @keyup.esc="resetNewName()"
                />
                <button
                    class="button transparent button-icon mx-2"
                    @click="saveName()"
                >
                    <fa-icon icon="check" />
                </button>
                <button
                    class="button transparent button-icon"
                    @click="resetNewName()"
                >
                    <fa-icon icon="times" />
                </button>
            </template>
            <template v-else>
                <p class="text-xl font-semibold tracking-wide">{{ user.name }}</p>
                <button class="button transparent button-icon ml-4" @click="editName()">
                    <fa-icon icon="edit" />
                </button>
            </template>
        </div>
        <div class="w-full h-80 bg-gray-100 overflow-hidden flex items-center justify-center mt-2 bg-circuit border-2 border-gray-400">
            <loader
                v-if="importingImage"
                loadingText="Lade Profilbild"
            />
            <template v-else-if="cropperSrc">
                <cropper
                    classname="cropper"
                    ref="cropper"
                    imageRestriction="area"
                    :src="cropperSrc"
                    :stencilProps="{
                        aspectRatio: 1/1
                    }"
                />
            </template>
            <fa-icon
                v-else-if="!user.sessionImages[user.sessionId]"
                icon="question"
                class="text-black"
                size="10x"
            />
            <img v-else :src="user.sessionImages[user.sessionId]" />
        </div>
        <div
            class="mt-2 flex justify-center"
            :class="{ hidden: cropperSrc }"
        >
            <span
                class="button cursor-pointer w-full"
                :class="{ disabled: savingImage }"
                @click="$refs.fileImport.click()"
            >
                <input type="file" ref="fileImport" class="hidden" accept="image/*"
                    @change="handleImage" />
                Neues Profilbild erstellen
            </span>
        </div>
        <div v-if="cropperSrc" class="mt-2 w-full flex justify-center">
            <button
                class="w-1/2 mr-1 button red"
                :disabled="savingImage"
                @click="abort()"
            >
                Abbrechen
            </button>
            <button
                class="w-1/2 ml-1 button green"
                :disabled="savingImage"
                @click="sizeImage()"
            >
                Speichern
                <loader
                    v-if="savingImage"
                    class="ml-2 -mr-2"
                />
            </button>
        </div>
        <div
            v-if="!cropperSrc"
            class="button cursor-pointer w-full mt-2 green"
            @click="$router.push('/')"
        >
            Zur Abstimmung
        </div>
    </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper"
import { mapState } from "vuex"

export default {
    components: { Cropper },
    data () {
        return {
            cropperSrc: null,
            editingName: false,
            newName: '',
            importingImage: false, // process after selecting image / taking picture and displaying in cropper
            savingImage: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    mounted () {
        if (this.user.imageExisted) {
            this.$store.commit('user/resetImageExisted')
            this.$refs.fileImport.click()
        }
        if (this.$route.params.openDialog) {
            this.$refs.fileImport.click()
        }
    },
    methods: {
        abort () {
            this.cropperSrc = null
            this.$refs.fileImport.value = null
        },
        handleImage (e) {
            this.importingImage = true
            const input = e.target

            if (input.files && input.files[0]) {
                const fileObject = input.files[0]
                const reader = new FileReader()

                reader.onloadend = (loadEvent) => {
                    this.importingImage = false
                    this.cropperSrc = loadEvent.target.result
                }

                reader.readAsDataURL(fileObject)
            }
        },
        sizeImage () {
            this.savingImage = true
            const result = this.$refs.cropper.getResult()

            const image = new Image();
            image.onload = async () => {

                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')

                const maxSize = 300
                const imageSize = result.canvas.width

                const scale = imageSize < maxSize ? 1 : maxSize / imageSize

                const scaledSize = imageSize * scale

                canvas.width = scaledSize
                canvas.height = scaledSize

                ctx.drawImage(image, 0, 0, scaledSize, scaledSize)

                const route = this.user.isAdmin ? 'admin' : 'session'
                const id = this.user.isAdmin ? this.user.adminId : this.user.sessionId

                const { data } = await this.axios.put(route, { id,    image: canvas.toDataURL() })

                if (!data.reasons) {
                    this.$store.dispatch('user/loadSessionImages',
                        { sessionIds: [this.user.sessionId], forceReload: true })
                } else {
                    console.log('da ging was schief: ', data.reasons)
                }
                this.abort()
                this.savingImage = false
            }
            image.src = result.canvas.toDataURL()
        },
        editName () {
            this.newName = this.user.name
            this.editingName = true
            this.$nextTick(() => {
                document.getElementById('newNameInput').focus()
            })
        },
        resetNewName () {
            this.editingName = false
            this.newName = ''
        },
        saveName () {
            if (this.newName.length < 3) return

            this.$store.dispatch('user/rename', this.newName)

            this.resetNewName()
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
