<template>
    <div class="w-full text-center">
        <div class="inline-flex flex-col">
            <div v-if="!voteable" class="my-1">
                <p class="bg-red-700 text-center p-2 font-bold">
                    Abstimmung zur Zeit
                    <button v-if="isAdmin" class="button-text font-bold" @click="$router.wrapPush('/settings')">deaktiviert</button>
                    <template v-else>deaktiviert</template>
                </p>
            </div>
            <div v-if="revoteTooFastIds.length" class="flex items-center justify-center my-1">
                <span class="flex flex-no-wrap items-center px-4 py-2 mb-1 bg-orange-600 rounded-full text-white">
                    <fa-icon icon="info-circle" />
                    <p class="ml-2 font-semibold">
                        Du kannst pro Song nur alle {{ cooldown }} Minuten abstimmen
                    </p>
                </span>
            </div>
            <div
                v-if="cherryPick"
                class="flex flex-col mt-2"
                title="Dieser Song wurde von einem Administrator als nächster Titel bestimmt"
            >
                <div class="flex justify-between bg-yellow-600">
                    <div class="flex flex-no-wrap items-center">
                        <fa-icon icon="medal" class="ml-2" />
                        <p class="ml-2 font-semibold font-mono tracking-wide">Cherry Pick</p>
                    </div>
                    <div class="flex items-center text-gray-200 my-1">
                        <button
                            v-if="isAdmin"
                            class="button transparent button-icon mr-1"
                            title="Cherry Pick löschen"
                            @click.stop="resetCherryPick()"
                        >
                            <fa-icon icon="trash" />
                        </button>
                    </div>
                </div>
                <div class="track-container">
                    <track-card
                        :track="cherryPick"
                        size="md"
                        :titleStyle="[ 'tracking-wide', 'font-semibold', 'text-white' ]"
                        :artistStyle="[ 'text-sm', 'text-white' ]"
                        :albumStyle="[ 'text-sm', 'text-white' ]"
                    />
                </div>
            </div>
            <div
                v-for="(track, i) in tracklist"
                :key="track.id"
                class="flex flex-col my-2"
            >
                <div
                    class="flex justify-between bg-gray-800 transition-colors duration-1000"
                    :class="{'bg-green-600': voteStatus(track.id, submittedIds, revoteTooFastIds) === 'success'}"
                >
                    <div class="flex flex-no-wrap items-center tracking-wide font-semibold text-gray-200">
                        <p class="w-20 pl-2 text-left font-mono whitespace-no-wrap text-lg md:text-xl"># {{ pre00(i + 1) }}</p>
                        <p v-if="voteStatus(track.id, submittedIds, revoteTooFastIds) === 'success'">Abgestimmt!</p>
                        <template v-else-if="voteStatus(track.id, submittedIds, revoteTooFastIds) === 'revoteTooFast'">
                            <p v-if="localCooldown(track.id)">
                                Vor {{ toLocalTime(localCooldown(track.id), { relative: true }) }} abgestimmt
                            </p>
                            <p v-else>
                                Du darfst noch nicht erneut abstimmen
                            </p>
                        </template>
                    </div>
                    <div
                        class="flex items-center my-1"
                        :class="votedTrackIndex(track.id) > -1 ? 'voted' : 'text-gray-200'"
                    >
                        <button
                            v-if="isAdmin && (!cherryPick || cherryPick.id !== track.id)"
                            class="button-icon transparent"
                            title="Song als Cherry Pick wählen"
                            @click.stop="chooseCherryPick(track.id)"
                        >
                            <fa-icon icon="medal" />
                        </button>
                        <button
                            v-if="isAdmin"
                            class="button-icon transparent"
                            :title="track.locked ? 'Song entsperren' : 'Song sperren'"
                            @click.stop="lockTrack(track.id, track.locked)"
                        >
                            <fa-icon :icon="track.locked ? 'lock-open' : 'lock'" />
                        </button>
                        <button
                            v-if="isAdmin"
                            class="button-icon transparent"
                            title="Song entfernen"
                            @click.stop="removeFromTracklist(track.id)"
                        >
                            <fa-icon icon="trash" />
                        </button>
                        <button
                            v-if="voteable"
                            class="button-icon transparent"
                            :class="{ voting: votes.includes(track.id) }"
                            title="Abstimmen"
                            @click="toggleVote(track.id)"
                        >
                            <fa-icon icon="thumbs-up" />
                        </button>
                        <button
                            v-if="voteable"
                            class="transparent button-icon font-semibold font-mono text-xl"
                            :class="{ active: metaTrackId === track.id }"
                            @click.stop="track.votes.length > 0 ? toggleTrackmeta(i) : () => { return }"
                        >
                            <span>{{ track.votes.length }}</span>
                        </button>
                    </div>
                </div>
                <div
                    class="track-container"
                    :class="{ voted: sessionsVoted(track.votes) }"
                    @click="toggleVote(track.id)"
                >
                    <track-card
                        :track="track"
                        size="md"
                        :titleStyle="[ 'tracking-wide', 'font-semibold', 'text-white' ]"
                        :artistStyle="[ 'text-sm', 'text-white' ]"
                        :albumStyle="[ 'text-sm', 'text-white' ]"
                    />
                </div>
                <div
                    v-if="metaTrackId === track.id"
                    class="relative flex flex-wrap bg-gray-600 p-2"
                >
                    <div
                        v-for="voter of unifiedVotes(track.votes)"
                        :key="voter.id"
                        class="bg-white relative mr-2 flex flex-no-wrap items-center rounded-full border-2 border-white cursor-pointer"
                        :class="{ active: showVoterNameId === voter.id }"
                        @click="toggleShowVoterName(voter.id)"
                    >
                        <div class="w-12 h-12 rounded-full overflow-hidden bg-yellow-600 flex items-center justify-center">
                            <img v-if="profileImages[voter.id]"
                                :src="profileImages[voter.id]" alt="Bild nicht geladen"/>
                            <fa-icon v-else icon="user" />
                        </div>
                        <span v-if="voter.count > 1"
                            class="absolute right-0 top-0 font-bold bg-gray-900 text-white w-6 h-6 rounded-full
                            flex items-center justify-center transform translate-x-2 -translate-y-1"
                        >
                            <p>{{ voter.count }}</p>
                        </span>
                        <p
                            v-if="showVoterNameId === voter.id"
                            class="px-2 truncate max-w-xs font-semibold text-black"
                        >
                            {{ voter.name }}
                        </p>
                    </div>
                    <div class="absolute right-0 mr-2 mt-2">
                        <button class="button transparent button-icon" @click="metaTrackId = null">
                            <fa-icon icon="chevron-up" />
                        </button>
                    </div>
                </div>
            </div>
            <div
                v-if="tracklist.length === 0"
                class="w-full"
            >
                <p class="text-center font-semibold mb-3">Kein Track zur Abstimmung</p>
                <p class="text-center">Verwende die Suche und füge einen Track hinzu</p>
            </div>
            <button
                v-else
                class="button green"
                @click="loadMore()"
            >
                Mehr Songs laden
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import timeFormatter from '../mixins/timeFormatter'
import numberFormatter from '../mixins/numberFormatter'
import voteUnifier from '../mixins/voteUnifier'
import voteStatusMixin from '../mixins/voteStatusMixin'
import TrackCard from '../components/TrackCard.vue'

export default {
    components: { TrackCard },
    computed: {
        ...mapState({
            tracklistUnfiltered: state => state.tracklist.tracks,
            submittedIds: state => state.tracklist.submittedIds,
            cooldownIds: state => state.tracklist.cooldownIds,
            revoteTooFastIds: state => state.tracklist.revoteTooFastIds,
            lockedIds: state => state.tracklist.lockedIds,
            localVotes: state => state.tracklist.localVotes,
            cooldown: state => state.admin.revoteCooldown,
            voteable: state => state.admin.voteable,
            isAdmin: state => state.user.isAdmin,
            profileImages: state => state.user.sessionImages,
            cherryPick: state => state.player.cherryPick
        }),
        tracklist () {
            if (!this.cherryPick) {
                return this.tracklistUnfiltered;
            }

            const tlFiltered = [];
            for (const track of this.tracklistUnfiltered) {
                if(track.id === this.cherryPick.id) {
                    continue;
                }
                tlFiltered.push(track);
            }
            return tlFiltered;
        }
    },
    mixins: [
        timeFormatter,
        numberFormatter,
        voteUnifier,
        voteStatusMixin
    ],
    data () {
        return {
            delayRequest: null,
            votes: [],
            metaTrackId: null,
            showVoterNameId: null,
            loading: false
        }
    },
    mounted () {
        this.$store.dispatch('tracklist/load', { force: true, diff: false, numDiffTracks: 0 })
        this.$store.dispatch('tracklist/poll', { diff: false, isTheater: false })
    },
    destroyed () {
        this.$store.dispatch('tracklist/clearPoll')
    },
    methods: {
        toggleVote (trackId) {
            if (this.delayRequest) {
                clearTimeout(this.delayRequest)
            }

            const voteIndex = this.votedTrackIndex(trackId)
            if (voteIndex > -1) {
                this.votes.splice(voteIndex, 1)
            } else this.votes.push(trackId)

            this.delayRequest = setTimeout(() => {
                this.commitVotes()
                this.votes = []
            }, 500)
        },
        commitVotes () {
            if (this.delayRequest) {
                clearTimeout(this.delayRequest)
                this.delayRequest = null
            }
            if (this.votes.length > -1) {
                this.$store.dispatch('tracklist/vote', this.votes)
            }
        },
        votedTrackIndex (trackId) {
            return this.votes.findIndex(id => id === trackId)
        },
        toggleTrackmeta (trackIndex) {
            const trackOjb = this.tracklist[trackIndex]
            // load desired profile images of voters
            const sessionIds = this.unifiedVotes(trackOjb.votes).map(session => session.id)

            if (this.metaTrackId === trackOjb.id) {
                // close track meta
                this.metaTrackId = null
            } else {
                this.$store.dispatch('user/loadSessionImages', sessionIds)
                this.metaTrackId = trackOjb.id
            }
        },
        toggleShowVoterName (voterId) {
            if (this.showVoterNameId === voterId) {
                this.showVoterNameId = null
            } else {
                this.showVoterNameId = voterId
            }
        },
        sessionsVoted (votes) {
            return votes.some(vote => vote && vote.id === this.$store.state.user.sessionId)
        },
        localCooldown (trackId) {
            const localVote = this.localVotes.find(vote => vote && vote.trackId === trackId)
            return localVote && !localVote.unsafe ? localVote.timestamp : null
        },
        async lockTrack (trackId, locked) {
            this.loarding = true
            const { data } = await this.axios.put('library/lockTrack',
                { trackId, locked: !locked })

            if (!data.reasons) {
                this.$store.commit('search/setTrackLocked', { id: trackId, locked: !locked })
                this.$store.commit('tracklist/removeLocal', trackId)
            }
            this.loading = false
        },
        chooseCherryPick(trackId) {
            this.$store.dispatch('player/cherryPick', trackId);
        },
        resetCherryPick() {
            this.$store.dispatch('player/resetCherryPick');
        },
        async removeFromTracklist(trackId) {
            this.loading = true
            const { data } = await this.axios.put('tracklist/removeVotes', { trackId })
            
            if (!data.reasons) {
                this.$store.commit('tracklist/removeLocal', trackId)
            }
            this.loading = false
        },
        loadMore() {
            this.$store.dispatch('tracklist/load', { more: true })
        }
    }
}
</script>

<style lang="sass" scoped>
.track-container
    @apply bg-gray-700 text-white p-2 cursor-pointer border-b-2 border-l-2 border-r-2 border-transparent

    .voting
        @apply text-green-500

        &:hover
            @apply bg-white font-bold text-gray-700

        &.active
            @apply bg-white font-bold text-gray-700

.track-meta
    @apply relative flex flex-wrap bg-gray-600 p-2

    .voter-chip
        @apply bg-white relative mr-2 flex flex-no-wrap items-center rounded-full border-2 border-white cursor-pointer

        &.active
            @apply

        .profile-img-wrapper
            @apply w-12 h-12 rounded-full overflow-hidden bg-yellow-600 flex items-center justify-center

        .vote-counter
            @apply absolute right-0 top-0 font-bold bg-gray-900 text-white w-6 h-6 rounded-full
            @apply flex items-center justify-center
            transform: translate(10px, -5px)
</style>
