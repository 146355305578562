<template>
  <div class="flex justify-center">
    <div class="flex flex-col">
      <div v-for="(entry, i) in history"
        :key="`history-entry-${i}`"
        class="flex flex-col justify-around mt-2 bg-gray-700"
      >
        <div class="text-center px-2 py-1 font-semibold bg-gray-800">
          <p>gespielt vor {{ toLocalTime(entry.timestamp, { relative: true }) }}</p>
        </div>
        <div class="p-2">
          <track-card
            :track="entry.track"
            size="md"
          />
        </div>
      </div>
      <div class="my-2">
        <button class="button green w-full"
          @click="$emit('load-more')"
        >
          Mehr Laden
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TrackCard from './TrackCard.vue'
import timeFormatter from '../mixins/timeFormatter'

export default {
  components: { TrackCard },
  mixins: [ timeFormatter ],
  props: {
    history: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>

</style>
