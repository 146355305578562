<template>
  <nav class="w-full fixed z-10 flex flex-no-wrap items-center justify-center top-0 bg-gray-800 py-2 border-b-2 border-gray-200 grid grid-cols-3 auto-cols-max">
    <div class="hidden lg:block"></div>
    <div class="col-span-2 lg:col-span-1 ml-2 justify-center">
      <song-search v-if="loggedIn" />
    </div>
    <div class="flex justify-end mr-2">
      <user-menu v-if="loggedIn" />
    </div>
  </nav>
</template>

<script>
import SongSearch from './SongSearch.vue'
import UserMenu from './UserMenu.vue'
import { mapState } from 'vuex'

export default {
  components: {
    SongSearch, UserMenu
  },
  computed: {
    ...mapState({
      loggedIn: state => state.user.loggedIn
    })
  }
}
</script>

<style lang="sass" scoped>

</style>
