const baseState = () => ({
  available: [],
  creationError: '',
  importError: '',
  savingTracks: false,
  reasons: [],
  currentPlaylist: null,
  trackToAdd: null
})

const state = baseState()

const mutations = {
  setAvailable (state, value) {
    state.available = value
  },
  resetAvailable (state) {
    state.available = []
  },
  setCurrentPlaylist (state, value) {
    state.currentPlaylist = value;
  },
  addPlaylist (state, value) {
    state.available.splice(1, 0, value)
  },
  setPlaylist (state, value) {
    const index = state.available.findIndex(playlist => playlist.id === value.id)
    if (index > -1) {
      state.available[index] = value
    }
  },
  rename (state, value) {
    const index = state.available.findIndex(playlist => playlist.id === value.id)
    if (index > -1) {
      state.available[index].name = value.name
    }
  },
  remove (state, playlistId) {
    const index = state.available.findIndex(playlist => playlist.id === playlistId)
    if (index > -1) {
      state.available.splice(index, 1)
    }
  },
  addTrack (state, value) {
    const index = state.available.findIndex(playlist => playlist.id === value.playlistId)
    if (index > -1) {
      state.available[index].tracks.push(value.trackId)
    }
  },
  setSavingTracks (state, value) {
    state.savingTracks = value
  },
  setReasons (state, reasons) {
    state.reasons = state.reasons.concat(reasons)
  },
  resetReasons (state) {
    state.reasons = []
  },
  setTrackToAdd (state, track) {
    state.trackToAdd = track
  },
  resetTrackToAdd (state) {
    state.trackToAdd = null
  }
}

const actions = {
  async loadAvailable ({ commit }) {
    commit('resetReasons')
    const { data } = await this._vm.axios.get('playlist')

    if (!data.reasons) {
      commit('setAvailable', data)
    } else {
      commit('setReasons', data.reasons)
    }
  },
  async new ({ commit, dispatch }, { name, trackIds = [] }) {
    commit('resetReasons')
    const playlist = { name }

    const { data } = await this._vm.axios.post('playlist/new', playlist)

    if (!data.reasons) {
      commit('addPlaylist', data)
      if (trackIds.length > 0) {
        dispatch('saveTracks', { id: data.id, trackIds })
      }
    } else {
      commit('setReasons', data.reasons)
    }
  },
  async rename ({ commit }, { playlistId, name }) {
    commit('resetReasons')
    const playlist = { id: playlistId, name }
    const { data } = await this._vm.axios.put('playlist/rename', playlist)

    if (!data.reasons) {
      commit('rename', playlist)
    } else {
      commit('setReasons', data.reasons)
    }
  },
  async delete ({ commit }, playlistId) {
    commit('resetReasons')
    const { data } = await this._vm.axios.put('playlist/delete', { id: playlistId })

    if (!data.reasons) {
      commit('remove', playlistId)
    } else {
      commit('setReasons', data.reasons)
    }
  },
  async saveTracks ({ commit }, { playlistId, trackIds }) {
    commit('resetReasons')
    commit('setSavingTracks', true)
    const playlist = { id: playlistId, trackIds }
    const { data } = await this._vm.axios.put('playlist/tracks', playlist)

    if (!data.reasons) {
      commit('setPlaylist', playlist)
    } else {
      commit('setReasons', data.reasons)
    }
    commit('setSavingTracks', false)
  },
  async addTrack ({ commit }, { playlistId, trackId }) {
    commit('resetReasons')
    const { data } = await this._vm.axios.put('playlist/addTrack',
      { id: playlistId, trackId })
    if (!data.reasons) {
      commit('addTrack', { playlistId, trackId })
    } else {
      commit('setReasons', data.reasons)
    }
  },
  async importFromSpotify ({ commit, state }, uri) {
    commit('resetReasons')

    if (uri.startsWith("https://open.spotify.com/playlist/")) {
      uri = uri.substring(34);
      const questionMark = uri.indexOf("?");
      if (questionMark >= 0) {
        uri = uri.substring(0, questionMark);
      }
    } 
    
    if (!uri.startsWith("spotify:playlist:")) {
      uri = "spotify:playlist:" + uri;
    }

    for(const playlist of state.available) {
      if(playlist.uri === uri) {
        commit('setReasons', "existing");
        return;
      }
    }

    const { data } = await this._vm.axios.post('playlist/import', { uri })

    return new Promise((resolve) => {

      if (!data.reasons) {
        commit('addPlaylist', data)
      } else {
        commit('setReasons', data.reasons)
      }
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
